import React from "react";
import "./App.css";

import AfterRegistration from "./components/pages/email_verification/AfterRegistration";
import PrivacyPolicyComponent from "./components/pages/PrivacyPolicyComponent";
import TermsConditionComponent from "./components/pages/TermsConditionComponent";
import Landingpage from "./components/pages/Landingpage";

import { Routes, Route } from "react-router-dom";
import Review from "./components/pages/review/review";
import { Layout } from "./components/pages/shop_now";
import { ProductListing } from "./components/pages/shop_now/product_listing";
import { ProductDetails } from "./components/pages/shop_now/product_details";
import DeleteMyAccount from "./components/pages/delete_my_account/delete_my_account";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="email_verification/"
          element={<AfterRegistration />}
        />
        <Route
          exact
          path="/terms-and-condition"
          element={<TermsConditionComponent />}
        />
        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPolicyComponent />}
        />
        <Route exact path="/review/:id" element={<Review />} />
        <Route exact path="/" element={<Landingpage />} />
        <Route exact path="/delete-my-account" element={<DeleteMyAccount />} />
        <Route
          exact
          path="/shop_now"
          element={
            <Layout>
              <ProductListing />
            </Layout>
          }
        />
        <Route
          exact
          path="/shop_now/:id"
          element={
            <Layout>
              <ProductDetails />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
