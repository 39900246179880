import React from "react";
import { serviceSectionData } from "./tagon_landingpage_data/landingpageData";
import services_mobileImages from "./images/services_mobileImages.png";

const LandingServiceComponent = () => {
  return (
    <main>
      <div className="servicesWrapper">
        <div className="servicesShortHead">
          <h2>Why TagOn ?</h2>
          <span>
            Offering exquisite & comprehensive selection of makeup, skincare,
            haircare, and fashion categories range of products
          </span>
        </div>
        <div className="servicesContainer">
          <div className="serviceContainer-text">
            {serviceSectionData.map(
              (
                { serviceQuoteImg, serviceQuoteHead, serviceQuoteDescr },
                index
              ) => {
                return (
                  <div className="servicesQuote" key={index}>
                    <img src={serviceQuoteImg} alt={serviceQuoteHead} />
                    <div className="servicsInfo">
                      <h3>{serviceQuoteHead}</h3>
                      <p>{serviceQuoteDescr}</p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="servicesImageSection">
            <img src={services_mobileImages} alt="mobile" />
          </div>
        </div>
      </div>
    </main>
  );
};
export default LandingServiceComponent;
