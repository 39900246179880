import { Carousel } from "./components/carousel/carousel";
import one from "./assets/advertisements/1.png";
import two from "./assets/advertisements/2.png";
import three from "./assets/advertisements/3.png";
import four from "./assets/advertisements/4.png";
import five from "./assets/advertisements/5.png";
import six from "./assets/advertisements/6.png";
import seven from "./assets/advertisements/7.png";
import eight from "./assets/advertisements/8.png";
import Card from "./components/card/card";
import { data } from "./data";
import { useEffect } from "react";

export function ProductListing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="p-5" />
      <Carousel images={[one, two, three, four, five, six, seven, eight]} />
      <div className="section-wrapper">
        <div className="section">
          <div className="section-title">Deals Of The Day</div>
          <div className="p-5" />
          <div className="cards">
            {data.slice(0, data.length - 5).map((d) => (
              <Card data={d} />
            ))}
          </div>
        </div>
      </div>
      <div className="p-10" />
      <div className="section-wrapper">
        <div className="section">
          <div className="section-title">Stay Safe With...</div>
          <div className="p-5" />
          <div className="cards">
            {data.slice(data.length - 5).map((d) => (
              <Card data={d} />
            ))}
          </div>
        </div>
      </div>
      <div className="p-5" />
      <div className="p-10" />
    </>
  );
}
