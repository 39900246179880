import "./card.css";
import { ReactComponent as StarFilledIcon } from "../../assets/starFilled.svg";
import { ReactComponent as StarIcon } from "../../assets/star.svg";
import { Link } from "react-router-dom";

export default function Card({ data }) {
  return (
    <Link to={"/shop_now/" + data.id} className="card">
      <img
        className="card-image"
        src={data.product_images[0].image}
        alt="product"
      />
      <div className="card-title">{wrapText(data.title)}</div>
      {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Stars count={data.stars} />
        <div style={{ fontSize: "13px" }}>{data.star_count}</div>
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "end",
          gap: "8px",
          fontSize: "17px",
        }}
      >
        <div style={{ fontWeight: 500 }}>
          {data.country_details.currency_symbol}
          {data.country_details.discount_price}
        </div>
        <div
          style={{
            fontWeight: 500,
            textDecoration: "line-through",
            color: "#949292",
          }}
        >
          {data.country_details.currency_symbol}
          {data.country_details.product_price}
        </div>
        <div style={{ fontSize: "12px", color: "#B00" }}>
          {data.country_details.discout_percent + "%"}
        </div>
      </div>
    </Link>
  );
}

export function Stars({ count }) {
  return (
    <div className="stars">
      {[...Array(5)].map((_asdf, idx) => {
        if (idx < count) {
          return <StarFilledIcon key={idx} width={20} />;
        }
        return <StarIcon key={idx} width={20} />;
      })}
    </div>
  );
}

const wrapText = (text) => {
  if (text.length > 50) {
    return text.slice(0, 50) + "...";
  }
  return text;
};
