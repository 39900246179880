import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const LandingContactForm = () => {
  const initialValues = {
    visitorName: "",
    visitorEmail: "",
    visitorMessage: "",
  };

  const onSubmit = async (values) => {
    const body = {
      visitorName: values.visitorName,
      visitorEmail: values.visitorEmail,
      visitorMessage: values.visitorMessage,
    };
    const contactFormAPI = `${process.env.REACT_APP_API}/web/contact/`;

    try {
      axios.post(contactFormAPI, body, {
        headers: { "Content-Type": "application/json" },
      });
      toast("Your details has been submitted successfully");
    } catch (error) {}
  };

  const validate = (values) => {
    let errors = {};

    if (!values.visitorName) {
      errors.visitorName = "*Name field is Required";
    } else if (values.visitorName.length < 2) {
      errors.visitorName = "Characters  must be 2  or more";
    } else if (values.visitorName.length > 30) {
      errors.visitorName = "Characters must be less than 30";
    }
    // visitorName Validation

    if (!values.visitorEmail) {
      errors.visitorEmail = "*E-mail field is Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.visitorEmail)
    ) {
      errors.visitorEmail = "Invalid email address";
    }
    // E-mail Validation
    return errors;
  };

  const formik = useFormik({ initialValues, onSubmit, validate });

  return (
    <div className="form-container">
      <h2>CONTACT US</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form_control">
          <input
            type="text"
            name="visitorName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visitorName}
            placeholder="Name"
          />
          {formik.touched.visitorName && formik.errors.visitorName ? (
            <div className="errors">{formik.errors.visitorName}</div>
          ) : null}
        </div>
        <div className="form_control">
          <input
            type="email"
            name="visitorEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visitorEmail}
            placeholder="Email"
          />
          {formik.touched.visitorEmail && formik.errors.visitorEmail ? (
            <div className="errors">{formik.errors.visitorEmail}</div>
          ) : null}
        </div>
        <div className="form_control">
          <textarea
            rows="8"
            type="textarea"
            name="visitorMessage"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visitorMessage}
            placeholder="Comment"
          ></textarea>
          {formik.touched.visitorMessage && formik.errors.visitorMessage ? (
            <div className="errors">{formik.errors.visitorMessage}</div>
          ) : null}
        </div>
        <button type="submit" className="contactBtn">
          SUBMIT
        </button>
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
