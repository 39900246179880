import React from "react";
import "./sass/landingpageMain.css";
import LandingHomeComponent from "./LandingHomeComponent";
import LandingAboutComponent from "./LandingAboutUsComponent";
import LandingServiceComponent from "./LandingServiceComponent";
import LanndingDemoComponent from "./LandingDemoComponent";
import LanndingContactComponent from "./LandingContactComponent";

const Landingpage = () => {
  return (
    <div>
      <LandingHomeComponent />
      <LandingAboutComponent />
      <LandingServiceComponent />
      <LanndingDemoComponent />
      <LanndingContactComponent />
    </div>
  );
};
export default Landingpage;
