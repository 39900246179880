import React, { useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AfterRegistration = () => {
  const [searchParams] = useSearchParams(window.location.search);

  const email = searchParams.get("email");

  useEffect(() => {
    const handleVerification = async () => {
      const verificationUrl = `${process.env.REACT_APP_API}/users-api/email_verify/`;

      const body = {
        data: decodeURIComponent(email),
      };

      try {
        const response = await axios.post(verificationUrl, body);
        let linkSuccessMsg = response.data.status;
        toast.success(linkSuccessMsg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        let linkErrorMsg = error.response.data.status;
        toast.error(linkErrorMsg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    handleVerification();
  }, [email]);

  return <ToastContainer />;
};
export default AfterRegistration;
