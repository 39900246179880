import { useEffect, useRef, useState } from "react";
import "./delete_my_account.css";
import { ReactComponent as LoadingIcon } from "./loading.svg";
import { FaAngleLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import CountryDropdown from "./countryDropdown";
import axios from "axios";
import tickAnimation from "./tink_animation.json";
import Lottie from "react-lottie";

const country_api = "/masters/country/";

const DeleteMyAccount = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [countries, setCoutries] = useState();
  const [token, setToken] = useState();
  const [selectedCountry, setSelectedCountry] = useState(
    countries?.[countries?.length - 1]
  );

  useEffect(() => {
    try {
      axios
        .get(process.env.REACT_APP_API + country_api)
        .then((res) => {
          setCoutries(res.data);
          setSelectedCountry(res.data?.[res.data?.length - 1]);
        })
        .catch((e) => {
          unknownError();
        });
    } catch {
      unknownError();
    }
  }, []);

  const set = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  if (!countries) {
    return (
      <div className="delete-my-account-page">
        <LoadingIcon height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="delete-my-account-page">
      {step === 0 && (
        <TakeNumber
          data={data}
          set={set}
          next={() => setStep(1)}
          countries={countries}
          token={token}
          setToken={setToken}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      )}
      {step === 1 && (
        <TakeOtp
          data={data}
          prev={() => setStep(0)}
          next={() => setStep(2)}
          token={token}
          setToken={setToken}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      )}
      {step === 2 && <Success />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

const TakeNumber = ({
  next,
  data,
  set,
  countries,
  token,
  setToken,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [saving, setSaving] = useState(false);
  return (
    <form
      className="d-page"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          selectedCountry?.country_regex &&
          !RegExp(selectedCountry.country_regex).test(
            selectedCountry.country_mobile_code.toString() + data.number
          )
        ) {
          toast.error("Invalid mobile number");
          return;
        }
        setSaving(true);
        try {
          axios
            .post(process.env.REACT_APP_API + "/web/otp/", {
              country_code: selectedCountry.country_mobile_code,
              mobile_number:
                selectedCountry.country_mobile_code.toString() + data.number,
            })
            .then((res) => {
              setToken(res.data.token);
              next();
              setSaving(false);
            })
            .catch((e) => {
              if (e?.response?.data?.data) {
                toast.error(e.response.data.data);
              } else {
                unknownError();
              }
              setSaving(false);
            });
        } catch {
          unknownError();
          setSaving(false);
        }
      }}
    >
      <div className="d-title">Enter your mobile number</div>
      <div style={{ padding: "5px" }} />
      <div className="d-description">
        To deactivate your account, please provide your mobile number.
      </div>
      <div style={{ padding: "15px" }} />
      <div style={{ display: "flex", gap: "10px" }}>
        <CountryDropdown
          countries={countries}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <input
          type="number"
          placeholder="Mobile number"
          value={data.nubmer}
          onChange={(e) => set("number", e.target.value)}
          required
          disabled={saving}
        />
      </div>
      <div style={{ padding: "15px" }} />
      <button className="submit-btn" disabled={saving}>
        Generate OTP
      </button>
    </form>
  );
};

const TakeOtp = ({ data, prev, token, setToken, selectedCountry, next }) => {
  const [focusedLetter, setFocusedLetter] = useState(0);
  const ref = useRef([]);
  const [letters, setLetters] = useState(["", "", "", "", "", ""]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (ref.current?.[focusedLetter]) {
      ref.current?.[focusedLetter]?.focus();
    }
  }, [focusedLetter]);

  const focusNext = () => {
    if (focusedLetter === 5) return;
    setFocusedLetter((prev) => prev + 1);
  };

  const focusPrev = () => {
    if (focusedLetter === 0) return;
    setFocusedLetter((prev) => prev - 1);
  };
  return (
    <form
      className="d-page"
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        try {
          axios
            .post(
              process.env.REACT_APP_API + "/web/del-acc",
              {
                otp: letters.join(""),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Token: token,
                },
              }
            )
            .then((res) => {
              next();
              setSaving(false);
            })
            .catch((e) => {
              if (e?.response?.data?.data) {
                toast.error(e.response.data.data);
              } else {
                unknownError();
              }
              setSaving(false);
            });
        } catch {
          unknownError();
          setSaving(false);
        }
      }}
    >
      <div className="back" onClick={() => prev()}>
        <FaAngleLeft className="back-icon" />
      </div>
      <div className="d-title">Enter provided OTP</div>
      <div style={{ padding: "5px" }} />
      <div className="d-description">
        An otp has been send to your mobile number {data.number}.
      </div>
      <div style={{ padding: "15px" }} />
      <div className="otps">
        {[...Array(6)].map((x, idx) => (
          <input
            key={idx}
            ref={(el) => (ref.current[idx] = el)}
            className="otp"
            type="number"
            value={letters[idx]}
            required
            disabled={saving}
            onKeyDown={(e) => {
              const input = e.key;
              if (input === "Enter") {
                // onSubmit();
              }
              if (input === "Backspace") {
                setLetters((prev) => {
                  const newLetters = [...prev];
                  newLetters[idx] = "";
                  return newLetters;
                });
                focusPrev();
              }
              if (input === "ArrowRight") {
                focusNext();
              }
              if (input === "ArrowLeft") {
                focusPrev();
              }
              if (input.length !== 1) return;
              let letter = input.charAt(0);
              if (letter <= "9" && letter >= "0") {
                setLetters((prev) => {
                  const newLetters = [...prev];
                  newLetters[idx] = letter;
                  return newLetters;
                });
                focusNext();
              }
            }}
            onChange={(e) => e.preventDefault()}
          />
        ))}
      </div>
      <div style={{ padding: "15px" }} />
      <div style={{ display: "flex", gap: "10px" }}>
        <ResendButton
          setToken={setToken}
          token={token}
          data={data}
          selectedCountry={selectedCountry}
        />
        <button className="submit-btn" style={{ flex: 1 }} disabled={saving}>
          Submit OTP
        </button>
      </div>
    </form>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: tickAnimation,
  };
  return (
    <div className="d-page">
      <div
        style={{
          margin: "auto",
          backgroundColor: "red",
          marginTop: "-60px",
          marginBottom: "-60px",
          zIndex: -1,
        }}
      >
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
      <center>
        <div className="d-title">Request Submitted Successfully !</div>
      </center>
      <div style={{ padding: "5px" }} />
      <center>
        <div className="d-description">
          Account delete request submitted successfully. Your account would be
          deleted within 30 days.
        </div>
      </center>
    </div>
  );
};

const ResendButton = ({ data, setToken, selectedCountry, token }) => {
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    const remove = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else {
        clearTimeout(remove);
      }
    }, 1000);
    return () => {
      clearTimeout(remove);
    };
  }, [seconds]);

  const resendOTP = () => {
    try {
      axios
        .post(
          process.env.REACT_APP_API + "/web/resend_otp/",
          {
            country_code: selectedCountry.country_mobile_code,
            mobile_number:
              selectedCountry.country_mobile_code.toString() + data.number,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Token: token,
            },
          }
        )
        .then((res) => {
          setToken(res.data.token);
          setSeconds(30);
        })
        .catch((e) => {
          if (e?.response?.data?.data) {
            toast.error(e.response.data.data);
          } else {
            unknownError();
          }
        });
    } catch {
      unknownError();
    }
  };
  return (
    <button
      type="button"
      className="submit-btn"
      disabled={seconds > 0}
      onClick={() => resendOTP()}
      style={{ flex: 1 }}
    >
      {seconds === 0 ? "Resend OTP" : `${seconds}s`}
    </button>
  );
};

const unknownError = () => {
  toast.error("Something went wrong.");
};

export default DeleteMyAccount;
