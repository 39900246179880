// CountryDropdown.js
import React, { useState } from "react";
import "./countryDropdown.css";
import { FaAngleDown } from "react-icons/fa";

const CountryDropdown = ({
  countries,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setIsDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="country-dropdown">
      <div
        className={`selected-country ${isDropdownVisible ? "active" : ""}`}
        onClick={toggleDropdown}
      >
        <img
          className="flag-img"
          src={selectedCountry.flag}
          alt={selectedCountry.name}
        />
        <FaAngleDown className="arrow" />
      </div>
      <div
        className={`country-list ${isDropdownVisible ? "show" : ""}`}
        id="country-list"
      >
        {countries.map((country) => (
          <div
            key={country.id}
            className="country-option"
            onClick={() => handleCountryChange(country)}
          >
            <img src={country.flag} alt={country.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryDropdown;
