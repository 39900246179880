import React from "react";
import { LandingContactForm } from "./tagon_landingpage_data/LandingContactForm";
import Instagram_Image from "./images/Instagram.png";
import Whatsapp_Image from "./images/WhatsApp.png";
import Facebook_Image from "./images/Facebook.png";
import Twitter_Image from "./images/Twitter Circled.png";
import Youtube_Image from "./images/YouTube.png";
import { Link } from "react-router-dom";

const LanndingContactComponent = () => {
  const date = new Date();
  const landingpageDate = date.getFullYear();

  return (
    <footer className="footer-wrapper" id="contactus">
      <div className="contact-section">
        <LandingContactForm />
      </div>
      <div className="social-section">
        <div className="social-icon">
          <div className="social-iconSection">
            <a href="https://tagonstore.com/" target="_black">
              <img src={Instagram_Image} alt="instagram_Icon" />
            </a>
            <a href="https://tagonstore.com/" target="_black">
              <img src={Whatsapp_Image} alt="whatsapp_Icon" />
            </a>
            <a href="https://tagonstore.com/" target="_black">
              <img src={Facebook_Image} alt="facebook_Icon" />
            </a>
            <a href="https://tagonstore.com/" target="_black">
              <img src={Twitter_Image} alt="twitter_Icon" />
            </a>
            <a href="https://tagonstore.com/" target="_black">
              <img src={Youtube_Image} alt="youtube_Icon" />
            </a>
          </div>
        </div>
        <div className="social-copyright">
          <p>
            <span>&#169;</span> Copyright | Look {landingpageDate}. All Rights
            Reserved
          </p>
        </div>
        <div className="social-privacySection">
          <h6>
            <Link to="/terms-and-condition">Terms & Condition</Link>
          </h6>
          <h6> | </h6>
          <h6>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </h6>
        </div>
      </div>
    </footer>
  );
};
export default LanndingContactComponent;
