export const data = [
  {
    id: 4,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Black/Yellow",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 15,
                name: "190g",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 14,
                name: "48mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 12,
                name: "48mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 2098.2,
      product_price: 2036.94,
      discout_percent: 2.0,
      discount_price: 1996.2,
      you_save: 40.74,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/1273ea74-3551-4ad3-b6d5-1cc0fb2a23ca.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7ee46825-c33a-40c0-9314-90535663d7a3.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/498b418e-35a9-4eaf-9f45-d42f40329611.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d00819ae-4f8c-4475-9f52-8865ae1aa585.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/e21b48d0-1f16-4299-bacc-091a15d5c357.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/6ee7f139-7abb-41e3-858f-51259343b122.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/b9aa4370-f97e-4eab-9c1b-774836ad4adf.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d963d926-48a5-4407-90b0-fd635cb88e9a.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 6,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 14,
                name: "48mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 15,
                name: "190g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 12,
                name: "48mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 1,
        color_name: "Red",
        hex_code: "#ff0000",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 2098.2,
      product_price: 2036.94,
      discout_percent: 2.0,
      discount_price: 1996.2,
      you_save: 40.74,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/b43572ab-33a5-4ae5-bc43-21da1125421b.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/6de8fd0f-9c04-4075-b545-292dfc85378a.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/ce89a0f9-e2bd-440c-bd88-b820b4922274.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d5548fde-fb5d-41e9-b6dc-b9edcc378852.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/f2d5481f-f43e-4cdb-811e-edd7a9bd6850.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/04fee91a-4554-4111-a577-2354d95fa153.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7b8f7971-77da-428b-88df-ac95044d81a4.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/451c32e4-6cc1-4b65-819e-bce71c4d9c25.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 8,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 14,
                name: "48mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 15,
                name: "190g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 12,
                name: "48mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 4,
        color_name: "Yellow",
        hex_code: "#FFFF00",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 2098.2,
      product_price: 2036.94,
      discout_percent: 2.0,
      discount_price: 1996.2,
      you_save: 40.74,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/456ebefa-a8e3-4e8f-9fe3-9237eeb70c1e.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d80bde15-f481-4ec4-944a-8e5c969509ad.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/9b9a5dd7-f037-4efe-a475-441fe6635cfe.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a6e88848-f375-4dab-b589-bbf6f571bccb.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/dacec0b2-e8fe-446e-9407-bef52c350e80.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/ab5407bc-cdb0-41d3-8b2d-feca1c85303e.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/782f7f0b-2752-4e03-85ad-5dadaf131dd1.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/972565bb-6879-4e5c-ba86-6440d464976f.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 10,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 14,
                name: "48mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 16,
                name: "110mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 18,
                name: "215g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 12,
                name: "48mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 1608.4,
      product_price: 1537.14,
      discout_percent: 2.0,
      discount_price: 1506.4,
      you_save: 30.74,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/710e7efc-9305-4b2c-9d99-4a3b90444302.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7c9f9380-1202-4348-a986-7b14647ff5e0.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/9023980a-d07c-458b-95fe-6ccabdfbf3b0.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/3cc1a755-51e2-4e8d-842f-b99d50ca8df1.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/875d3779-2af8-4b78-bebc-a9661cf20523.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/76e64fe5-ffc4-476e-8eb3-bcde12e411a4.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/66ae979a-16ce-42c9-986a-de21ae717125.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d5fc25de-3782-400b-8d06-5046d5f1e869.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 2,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 48mm(W) x 03mtr (L) Colour Black",
    short_description: "Adhesive Tape",
    description:
      "PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Brand Description",
            values: [
              {
                id: 49,
                name: "Pentagon Tapes, in one line is a 'Pressure sensitive adhesive tape expert'. For nearly 2 decades, it has been operating in this domain, and continues to offer, all kind of innovative 'tape solutions' to multiple segments of the industry and also to retail segment.",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Key Features",
            values: [
              {
                id: 50,
                name: "Features:\r\nPENTAGON Ultra High Performance Anti-Skid tape for indoor/outdoor creates a safer environment for walking, working, stepping and standing. Exceptionally strong & durable for applications like stairs, entryways, decks, ladders, concrete, metal, plastic, shoe grips, tile, laminate, mats, carpets, ramps, wet areas, slippery floor, skateboard grip Walk Bathtub & Shower Strips etc. Our tape can be used on rough or smooth surfaces. Waterproof, long lasting & peel resistant in any weather. Resistant to water, detergents and oils.",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product USP",
            values: [
              {
                id: 53,
                name: "Strong & Long Lasting Adhesive Suitable For All Kind Of Surfaces Easy to Clean & Mop Ideal For Slippery steps, Stairs, Floorings, Walkways & Ramps",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Description",
            values: [
              {
                id: 51,
                name: "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.\r\n\r\nIDEAL FOR EXTREME WEATHER - it can withstand temperatures between -20oc to 60oc . No cracking, peeling, disintegrating even under extreme hot or cold weather. Perfect for both INDOOR and OUTDOOR application all year around.\r\n\r\nHIGH TRACTION TO PREVENT SLIPS - HYPO-ALLERGENIC 80 Grit Aluminum Oxide traction pad boosts foot traction with a subtle texture, giving you utmost protection without sacrificing comfort. Provides extra foot traction for protection to your child, family and especiallu elderly on slippery surfaces.",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 12,
                name: "48mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Installation Guidelines",
            values: [
              {
                id: 52,
                name: "Ensure that the surface is completely free from dust & grease - The surface should be dry and free from any loose particles- Pinch and stretch a corner of the adhesive until the backing liner lifts from the tape for easier liner removal- Apply Anti-Skid tape on the desired application surface\r\n- ADHESION PROMOTER is required for some surface if the tape does not stick - Avoid contamination and finger touch to the adhesive - To attain the optimum bond,sufficient pressure should be applied evenly to the surface ,starting from the centre of the tape strip and work towards the edges (Dry for 24 hours on first use) ",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 14,
                name: "48mm",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 48mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 15,
                name: "190g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 3,
        color_name: "Black",
        hex_code: "#000000",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 1234.55,
      product_price: 1155.66,
      discout_percent: 2.0,
      discount_price: 1132.55,
      you_save: 23.11,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/81b1861e-422c-4b27-bbe1-e9d66e847ee7.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a0af2cce-5be3-4f98-8b30-b88b9589942f.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/58653e77-7f72-46f6-9de0-18b8308b135c.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/855ca16b-05d7-4115-b333-e2eefd18e6fa.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/372b2386-faaf-41d1-9d5f-c248ecd0f88d.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/690092fc-86ca-48e4-864f-40e2884c28ef.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/8afb6687-5ac1-4b01-ae5a-c24b33e33c12.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/98b1e402-e3e5-4f76-8580-ddd2bbe8d9cd.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 12,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON PRO Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Mirror Mounting Tapes provides \r\nIDEAL FOR HUMID CONDITIONS: It can withstand temperatures between -20oc to 60oc . No cracking, peeling, disintegrating even under extreme hot or cold weather.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Utility Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 20,
                name: "05mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 25,
                name: "18mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 26,
                name: "142mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 27,
                name: "235g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 24,
                name: "18mm(W) x 05mtrs (L) x 1.6mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Mirror Mounting Tape, 18mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 2,
        color_name: "White",
        hex_code: "#FFFFFF",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 1186.06,
      product_price: 1132.2,
      discout_percent: 2.0,
      discount_price: 1109.56,
      you_save: 22.64,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/5026719b-32b4-4517-b2c3-8ad6777d024b.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/5cf6e9c2-1900-42e2-9a65-26e93324008f.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/674d3ee7-e238-4359-aac3-a4af6b7aa280.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a699066e-fca2-43d1-872f-45a230386fea.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7aaee702-77d9-4ec5-a002-d4174408bb97.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/fe217de8-51c0-45ac-a50d-5f0467a78e23.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/14e5040c-c214-4cd0-b57a-93656d0c6c2d.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 3,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Black/Yellow",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 8,
                name: "24mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 10,
                name: "115g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 2,
                name: "24mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Black/Yellow - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 1162.58,
      product_price: 1082.22,
      discout_percent: 2.0,
      discount_price: 1060.58,
      you_save: 21.64,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/0b99648a-56d1-40df-83b6-34cae3694b26.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/8415a493-9cbe-471b-86ce-8e022daa208e.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/8a1f8324-3c6e-4f91-8b6e-e0f0fc4de27d.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/90261683-d8d7-4d80-abe5-3c15a2a70bba.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a6101fbb-be8f-4ae0-b3b8-26278d6766d5.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/adbf228a-7999-4421-b558-2825e732b8bd.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/f756b9b9-5b99-4bf4-852d-2d285cbe2eef.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/e1f11938-ccb1-4b74-805c-5c865582b853.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 7,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info -  Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info -  Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 7,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 8,
                name: "24mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 10,
                name: "115g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 2,
                name: "24mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Yellow - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 4,
        color_name: "Yellow",
        hex_code: "#FFFF00",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 102.0,
      sell_price: 1162.58,
      product_price: 1082.22,
      discout_percent: 2.0,
      discount_price: 1060.58,
      you_save: 21.64,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/861fd764-ecdf-48fc-9dff-c873e369e60c.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/547720c9-3708-4537-b375-5a15cb389bca.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/9235190e-d057-4322-ba53-cf68afd2e6f4.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/608d39b3-995d-48fc-ba9b-965139296c90.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/97d9f5f0-a469-458f-98cf-9e570f6030bf.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/f8cb5d04-9185-4909-9ef9-baca5099aea7.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/65f411e9-a2c7-4ff9-877d-a2b2a4c77241.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/41eecbab-e7a6-4c9b-8c38-bf13ceefdabf.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 5,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 10,
                name: "115g",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 9,
                name: "103mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 8,
                name: "24mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 2,
                name: "24mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Red - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 1,
        color_name: "Red",
        hex_code: "#ff0000",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 1137.08,
      product_price: 1082.22,
      discout_percent: 2.0,
      discount_price: 1060.58,
      you_save: 21.64,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/ac2d2348-a3f1-49b8-a15f-0fb11a34be44.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a52d747a-b601-4e7d-a3fd-a26404a3b864.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a1e9846d-aabd-4161-9a0b-a00a3629d738.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/ce868f8d-1c47-414a-96dd-fd8d7d18fec7.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/d99f6796-9c27-4845-9c2a-3c29600a6dbb.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/dc0daf16-8fbb-4814-aefa-146c13fbc469.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/f77375f3-6df2-4812-8158-88873b52521c.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/555101f0-4a49-499a-b6f5-386cb1b72adc.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 11,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON PRO Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Mirror Mounting Tapes provides \r\nIDEAL FOR HUMID CONDITIONS: It can withstand temperatures between -20oc to 60oc . No cracking, peeling, disintegrating even under extreme hot or cold weather.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Utility Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 20,
                name: "05mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 21,
                name: "12mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 22,
                name: "142mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 23,
                name: "165g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 19,
                name: "12mm(W) x 05mtrs (L) x 1.6mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Mirror Mounting Tape, 12mm(W) x 05mtr (L), Colour White - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 2,
        color_name: "White",
        hex_code: "#FFFFFF",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 876.18,
      product_price: 816.0,
      discout_percent: 2.0,
      discount_price: 799.68,
      you_save: 16.32,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/cc5d64e1-f51d-46a1-aac6-48bb134ee7af.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/98e6cd4f-b786-46eb-a993-8468548e07c9.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/8fb2f312-38a6-4de3-9164-76879eefcb73.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/db7242c5-8432-4818-b9ac-a1b660acd728.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/c50b4db0-77df-4572-929c-35fefb10c5f0.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/0dbc5b5f-c72c-456c-9fd4-9bfea3da71dc.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/c856a6ff-3ab0-484b-9d67-4266a8b41b0b.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 9,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 8,
                name: "24mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 16,
                name: "110mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 17,
                name: "130g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 2,
                name: "24mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L), Colour Clear - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 0.0,
      sell_price: 815.67,
      product_price: 832.32,
      discout_percent: 2.0,
      discount_price: 815.67,
      you_save: 16.65,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7ef96b22-aff8-4d3b-8595-0d221edb6b5c.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/789d54a3-7ac2-4ab1-9a73-dd1c541b3ed5.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/272117dd-73fd-4f3d-970d-dd5ae6a948c5.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/e70739be-4765-4ab1-87d7-aeed26746806.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/2f0505b5-3621-40b4-baca-dd409387790e.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/1774f6e7-988c-4990-ab63-20fac18ff33b.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/0ff0981b-a93f-4dde-9e35-16093a7261e9.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/257491c7-e478-44fd-8f0a-b6f89e5c09ad.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 14,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON PRO Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear",
    short_description: "Adhesive Tape",
    description:
      "IDEAL FOR HUMID CONDITIONS: it can withstand temperatures between -20oc to 60oc \r\nImproving holding strength and eliminating the need for mechanical fasteners Save your tools for another job, and mount your frames with the powerful adhesion of PENTAGON Ultra High Performance Frame Mounting Tape",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Utility Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 33,
                name: "18mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 34,
                name: "115mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 10,
                name: "115g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info -  Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 32,
                name: "18mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info -  Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info -  Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 29,
                name: "1 roll",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Frame Mounting Tape, 18mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 773.22,
      product_price: 710.94,
      discout_percent: 2.0,
      discount_price: 696.72,
      you_save: 14.22,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/52a6f78b-19ac-433e-a141-d70a67a38b86.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/539b446a-bf70-4d5f-8625-413848d1716b.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/0b69783c-dec2-4146-9032-a8fcb464b8b9.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/8530098c-0b66-4db7-981d-e6c5546dbcda.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/bbed3885-2dc4-4ef3-bfcc-523bbd579f00.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/e3aff623-7155-49e3-8061-cdbc662932e2.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/2a5f1e25-fcef-4b9c-99bb-063f0e060f47.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 1,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON Ultra High Performance Anti-Skid Tape, 24mm(W) x 03mtr (L) Colour Black",
    short_description: "Adhesive Tape",
    description:
      "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Safety Tapes",
    has_size: false,
    has_color: true,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Brand Description",
            values: [
              {
                id: 49,
                name: "Pentagon Tapes, in one line is a 'Pressure sensitive adhesive tape expert'. For nearly 2 decades, it has been operating in this domain, and continues to offer, all kind of innovative 'tape solutions' to multiple segments of the industry and also to retail segment.",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Key Features",
            values: [
              {
                id: 50,
                name: "Features:\r\nPENTAGON Ultra High Performance Anti-Skid tape for indoor/outdoor creates a safer environment for walking, working, stepping and standing. Exceptionally strong & durable for applications like stairs, entryways, decks, ladders, concrete, metal, plastic, shoe grips, tile, laminate, mats, carpets, ramps, wet areas, slippery floor, skateboard grip Walk Bathtub & Shower Strips etc. Our tape can be used on rough or smooth surfaces. Waterproof, long lasting & peel resistant in any weather. Resistant to water, detergents and oils.",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product USP",
            values: [
              {
                id: 53,
                name: '"Strong & Long Lasting Adhesive\r\n\r\nSuitable For All Kind Of Surfaces\r\n\r\nEasy to Clean & Mop\r\n\r\nIdeal For Slippery steps, Stairs, Floorings, Walkways & Ramps"',
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Description",
            values: [
              {
                id: 51,
                name: "HIGH QUALITY : PENTAGON Ultra High Performance Anti-Skid Tapes provides a strong barrier against water vapour, dilute acids, gases, oils, alcohols, and most importantly, UV rays. These qualities make this tape exceptionally durable and strong in different environment and weather. A great choice for Commercial and Residential use for high traffic area.\r\n\r\nIDEAL FOR EXTREME WEATHER - it can withstand temperatures between -20oc to 60oc . No cracking, peeling, disintegrating even under extreme hot or cold weather. Perfect for both INDOOR and OUTDOOR application all year around.\r\n\r\nHIGH TRACTION TO PREVENT SLIPS - HYPO-ALLERGENIC 80 Grit Aluminum Oxide traction pad boosts foot traction with a subtle texture, giving you utmost protection without sacrificing comfort. Provides extra foot traction for protection to your child, family and especiallu elderly on slippery surfaces.",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 2,
                name: "24mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 6,
                name: "1 roll with 10ml Adhesion Promoter",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Installation Guidelines",
            values: [
              {
                id: 52,
                name: '"Ensure that the surface is completely free from dust & grease \r\n- The surface should be dry and free from any loose particles\r\n- Pinch and stretch a corner of the adhesive until the backing liner lifts from the tape for easier liner removal\r\n- Apply Anti-Skid tape on the desired application surface\r\n- ADHESION PROMOTER is required for some surface if the tape does not stick\r\n- Avoid contamination and finger touch to the adhesive\r\n- To attain the optimum bond,sufficient pressure should be applied evenly to the surface ,starting from the centre of the tape strip and work towards the edges (Dry for 24 hours on first use) "',
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 7,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 8,
                name: "24mm",
              },
            ],
          },
          {
            product_type:
              "Other Info - Anti-Skid Tape, 24mm(W) x 03mtr (L) - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 10,
                name: "115g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Compatible Material",
        all_parameters: [
          {
            product_type: "Compatible Material - Anti-Skid Tape",
            product_property_type: "Compatible Material",
            values: [
              {
                id: 54,
                name: "Ceramic , Wood , Glass, Granite, Metal, Tiles, Marble",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [
      {
        id: 3,
        color_name: "Black",
        hex_code: "#000000",
      },
    ],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 705.25,
      product_price: 641.58,
      discout_percent: 2.0,
      discount_price: 628.75,
      you_save: 12.83,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/f6e5e3f3-961d-415b-af13-827a605edaa6.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7cb38989-1ba1-4614-bdcb-5257f8fd8f9f.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/785fbdc5-fe22-4cff-b453-db3ff8512924.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/9ffc063e-06d3-4439-b3f1-9daf4318e07a.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/517f726a-8ac0-4361-95fe-460d46dbef94.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/35ae1377-43fa-4d34-8813-6deb431e19ab.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/0f782234-5dd9-4552-aec1-85e04ee9991a.jpg",
      },
      {
        id: 8,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/3640ce00-806a-456d-960b-6b5707f8d319.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
  {
    id: 13,
    stars: 4,
    star_count: 124,
    title:
      "PENTAGON PRO Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear",
    short_description: "Adhesive Tape",
    description:
      "IDEAL FOR HUMID CONDITIONS : it can withstand temperatures between -20oc to 60oc \r\nImproving holding strength and eliminating the need for mechanical fasteners Save your tools for another job, and mount your frames with the powerful adhesion of PENTAGON Ultra High Performance Frame Mounting Tape.",
    first_category: "Home Improvements",
    second_category: "DIY Product",
    third_category: "Utility Tapes",
    has_size: false,
    has_color: false,
    information: [
      {
        product_info: "General Information",
        all_parameters: [
          {
            product_type:
              "General Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Manufacturer",
            values: [
              {
                id: 11,
                name: "Pentagon Tapes Pvt.Ltd",
              },
            ],
          },
          {
            product_type:
              "General Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Brand",
            values: [
              {
                id: 1,
                name: "Pentagon Tapes",
              },
            ],
          },
        ],
      },
      {
        product_info: "Additional Information",
        all_parameters: [
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Length",
            values: [
              {
                id: 13,
                name: "03mtrs",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Width",
            values: [
              {
                id: 21,
                name: "12mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Height",
            values: [
              {
                id: 30,
                name: "115mm",
              },
            ],
          },
          {
            product_type:
              "Additional Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Weight",
            values: [
              {
                id: 31,
                name: "70g",
              },
            ],
          },
        ],
      },
      {
        product_info: "Technical Information",
        all_parameters: [
          {
            product_type:
              "Technical Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product variants",
            values: [
              {
                id: 28,
                name: "12mm(W) x 03mtrs (L) x 0.8mm(T)",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product Self Life",
            values: [
              {
                id: 4,
                name: "1 Year",
              },
            ],
          },
          {
            product_type:
              "Technical Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Pack Content",
            values: [
              {
                id: 29,
                name: "1 roll",
              },
            ],
          },
        ],
      },
      {
        product_info: "Other Information",
        all_parameters: [
          {
            product_type:
              "Other Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Country Of Origin",
            values: [
              {
                id: 3,
                name: "India",
              },
            ],
          },
          {
            product_type:
              "Other Info - Frame Mounting Tape, 12mm(W) x 03mtrs (L), Colour Clear - PENTAGON",
            product_property_type: "Product Expirable",
            values: [
              {
                id: 5,
                name: "Yes",
              },
            ],
          },
        ],
      },
    ],
    is_returnable: true,
    is_cancellable: true,
    left_stock: null,
    size_label_chart: [],
    chart_data: [],
    color: [],
    data_upload: null,
    you_like_images: [],
    product_reviews: [
      {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        images: [],
        rating: 0,
      },
    ],
    product_content_reviews: [],
    seller_name: "Pentagon Tapes Pvt. Ltd.",
    product_global: false,
    rate_1_percent: 0,
    rate_2_percent: 0,
    rate_3_percent: 0,
    rate_4_percent: 0,
    rate_5_percent: 0,
    rating: 0,
    delivery_by: "",
    country_details: {
      cod: true,
      country: "IN",
      shipping_charges: 76.5,
      sell_price: 540.31,
      product_price: 473.28,
      discout_percent: 2.0,
      discount_price: 463.81,
      you_save: 9.47,
      currency_symbol: "₹",
      currency_code: "INR",
      charge: [
        {
          charge_name__charge_name: "Delivery Charges",
          charge_with_tax: 76.5,
        },
      ],
      packing_and_forwarding_charges: 0.0,
      check_pincode: [],
    },
    product_images: [
      {
        id: 1,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/32675a98-9442-49a1-a89c-822f0d49ed48.jpg",
      },
      {
        id: 2,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/a9c9a674-cea1-4837-9624-c01cadf0d8bd.jpg",
      },
      {
        id: 3,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/7f7587eb-feb8-4e80-9668-5d670e54ba64.jpg",
      },
      {
        id: 4,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/ad31ff76-8c04-4f39-bca7-56278f5da36c.jpg",
      },
      {
        id: 5,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/354d3c47-7ec2-4a2e-8096-0117750a5756.jpg",
      },
      {
        id: 6,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/3b70ce0b-a27a-420b-b3d6-80f75facb83c.jpg",
      },
      {
        id: 7,
        image:
          "https://luckywheel-bucket.s3.amazonaws.com/Tagon_Products/image/42635e61-a5e2-43d4-9049-6be3842233c7.jpg",
      },
    ],
    inventory: [],
    return_by: "",
  },
];
