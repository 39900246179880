import React, { useEffect } from "react";
import companyLogo from "./images/TagOn.png";
import { Link } from "react-router-dom";

const TermsConditionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="privacy-section">
      <nav>
        <Link to="/" className="logo">
          <img src={companyLogo} alt="tagon_logoImage" />
        </Link>
      </nav>
      <Article />
      <div style={{ padding: "20px" }} />
    </section>
  );
};

const Article = () => {
  return (
    <article className="prose">
      <h1 id="terms-of-use">TERMS AND CONDITIONS</h1>
      <p>
        These policies are published in accordance with the provisions of Rule
        3(1) of the Information Technology (Intermediary Guidelines and Digital
        Media Ethics Code) Rules, 2021, and are an &#39;electronic record&#39;
        as contemplated under section 2(t) of the Information Technology Act,
        2000 and the Rules thereunder. These Terms being an electronic record
        are generated by a computer system and do not require any physical or
        digital signatures.
      </p>
      <h2 id="access-to-the-app">ACCESS TO THE APP</h2>
      <p>
        <strong>
          The App is owned by TagOn Store, a subsidiary of PENTAGON Tapes
          Private Limited having registered office at 6th Floor, K Raheja Prime
          Marol. Indl. Socy, Sag Baug Rd., Marol Naka, Andheri (E), Mumbai -
          400059 (TagOn Store). GST : 27AADCP9667N1ZI. CIN :
          U25209MH2006PTC163982.{" "}
        </strong>
        Use of the App by the user (&quot;You&quot;/ &quot;User&quot;) is
        subject to the terms and conditions contained herein.
      </p>
      <p>
        Accessing, browsing or otherwise using the App will be construed as Your
        unequivocal and irrevocable acceptance and acknowledgement to the terms
        and conditions contained under Terms of Use and binding policies
        hereof. By using the App, You declare to have carefully read, understood
        and agree to be bound by these Terms of Use. TagOn Store may at its sole
        discretion allow or disallow access to App and reserves the right to
        change any of the terms and conditions contained herein at any point in
        time. It is Your responsibility to periodically review these Terms of
        Use any update and changes and Your continued use of the App will be
        considered as Your acceptance to such updates and changes.
      </p>
      <h2 id="eligibility">ELIGIBILITY</h2>
      <p>
        You should have completed 18 years of age and be competent to enter into
        a contract under Indian contract law. If You are under 18 years of age
        who is intending to avail the services of the App should do so through
        Your legal guardian in accordance with applicable law. 
      </p>
      <p>
        If You are using the App on behalf of any corporation, company,
        institution, association or other entity, the person should be
        authorized by such corporation, company, institution, association or
        other entity to use and access the App.
      </p>
      <p>
        TagOn Store reserves the right to refuse access to use the services
        offered at the App to new users or to terminate access granted to
        existing users at any time without according any reasons for doing so.
      </p>
      <h2 id="compliance-requirements">COMPLIANCE REQUIREMENTS</h2>
      <p>
        Every User of the App shall comply with all the applicable laws,
        including but not limited to the Foreign Exchange Management Act, 1999,
        notifications issued there under the Exchange Control Manual issued by
        the Reserve Bank of India, Customs Act, 1962, Information Technology
        Act, 2000, Prevention of Money Laundering Act, 2002, Foreign
        Contribution Regulation Act, 1976, Income Tax Act, 1961, Export Import
        Policy of the Government of India and shall include any amendments,
        notifications and Rules thereof under the aforesaid enactments
        applicable for using the App or buying/ selling any product or service
        through the App.
      </p>
      <h2 id="user-account">USER ACCOUNT</h2>
      <p>
        You may be required to register and set up an account (&quot;{" "}
        <strong>Account&quot;</strong> ) for using the App by entering an email
        address and password or by using any permitted social media the App
        account to sign in (&quot; <strong>Account Information</strong>&quot;).
        TagOn Store as its sole discretion may ask for the verification of Your
        Account Information provided by You and hence, it is advisable to
        provide a valid information e.g. like email address or mobile number
        etc. while creating an Account. The App will set-up an account and email
        the details of the Account at the email address provided. You can also
        create and update an Account through Customer Support.
      </p>
      <p>
        You can create one Account only and it is not permitted to transfer or
        interchange such Account to any other person. The list of information
        required to be provided by You, the manner of usage, protection and
        confidentiality of Your information and Account information are more
        specifically dealt under the App&#39;s Privacy Policy and You are
        requested to read the Privacy Policy in detail before sharing Your
        information or creating an Account.
      </p>
      <h2 id="payment-policy">PAYMENT POLICY</h2>
      <p>
        Payment is an integral part of an online shopping experience. All
        payments shall be collected through secured payment options.
      </p>
      <p>
        We accept all prepaid payment options such as all the major Credit
        Cards, Debit Cards, Net-Banking etc. TagOn Store may also offers
        industry-leading solution for Cash on Delivery.
      </p>
      <p>
        All payments for purchases made by You on the App shall only be in
        Indian Rupees. In case of a prepaid transaction, after payment for the
        order has been successfully received by TagOn Store, You will be sent a
        notification by email or SMS at the email address or mobile number
        provided to TagOn Store confirming receipt of payment. There may be
        limits to the amount of Cash on Delivery payments based on pin codes or
        the State in India where delivery is to be made. TagOn Store reserves
        the right to withdraw or block or limit Your purchases using Cash on
        Delivery basis transaction history.
      </p>
      <h2 id="credit-and-debit-card">Credit and Debit Card</h2>
      <p>
        TagOn Store accepts all Visa, Master Card, Maestro, American Express and
        Diners cards. You will be redirected to Your issuing bank&#39;s site,
        where You will have to follow the instruction to complete the payment.
      </p>
      <h2 id="net-banking">Net Banking</h2>
      <p>
        You can make payment for an order by using Your Net Banking account. The
        App accepts payments for Your orders to sellers through various banks.
        You will be redirected to Your bank&#39;s site where You will be able to
        make payment after entering in Your login id and password that is known
        only to You.
      </p>
      <h2 id="cash-on-delivery">Cash on Delivery</h2>
      <p>
        Cash on Delivery is one of the payment methods for making purchases on
        TagOn Store where You don&#39;t have to make any advance payment.
      </p>
      <p>
        Cash on Delivery is available as a payment method for all eligible
        products on TagOn Store. Eligible products will be indicated by the
        message [&#39;Eligible for Cash on Delivery&#39;] on the [&#39;product
        order page&#39;] for delivery in certain pin codes.
      </p>
      <h2 id="issues-with-payments-pending-payments-">
        Issues with Payments - Pending Payments :
      </h2>
      <p>
        Payments can sometimes fail or remain in the pending stage. Some of the
        reasons could be :
      </p>
      <ul>
        <li>
          <p>Incorrect debit or credit card details are entered.</p>
        </li>
        <li>
          <p>
            Card may be blocked, or card details are no longer valid or need to
            be updated.
          </p>
        </li>
        <li>
          <p>
            The page is accidentally closed or refreshed, or backspace is
            pressed while the transaction is being processed by Your bank.
          </p>
        </li>
        <li>
          <p>
            The connection between TagOn Store and Your bank or issuer failed
            due to some technical reasons and the transaction could not be
            completed.
          </p>
        </li>
        <li>
          <p>Card or bank or issuer is not recognised by TagOn Store.</p>
        </li>
        <li>
          <p>Incorrect login or password details are entered.</p>
        </li>
        <li>
          <p>
            Payment amount exceeds the limit mutually agreed to between You and
            Your bank or issuer.
          </p>
        </li>
      </ul>
      <p>
        If You face any of the above payment issues, try placing the order again
        in a short while or contact Your bank or issuer for technical
        assistance. TagOn Store is not liable for any loss or inconvenience
        caused to You when making payment or for any wrongful debits to Your
        cards or net banking accounts.
      </p>
      <h2 id="issuing-bank-terms-and-conditions">
        Issuing Bank Terms and Conditions
      </h2>
      <p>
        Users understand and acknowledge that all payments made by the Users
        using valid credit card/ Debit card/ Cash Card/ and other payment
        instruments or mechanism including net banking facility will be
        processed using a payment gateway or appropriate payment system
        infrastructure and the same is governed by a separate set of terms and
        conditions agreed between the User and the respective Issuing Bank and
        payment instrument issuing company.
      </p>
      <h2 id="requirements-of-permanent-account-number-pan-">
        Requirements of Permanent Account Number (PAN)
      </h2>
      <p>
        For all the purchase of an amount equal to or exceeding INR 2,00,000
        (INR two lakhs only), You will be required to upload a copy of Your PAN
        card, within such time as may be prescribed from the date of purchase,
        failing which, the purchase made by You will be automatically cancelled.
        The order shall also stand cancelled if there is a discrepancy between
        Your name in Account with TagOn Store the name printed on the PAN card.
      </p>
      <p>
        The requirement to provide a copy of PAN card is one time activity and
        You don&#39;t need to submit it again.
      </p>
      <h2 id="delivery-policy">DELIVERY POLICY</h2>
      <h3>Shipping Address</h3>
      <p>
        Your shipping address and pin code will be verified with the database of
        TagOn Store before You proceed to pay for Your purchase. In the event
        Your order is not serviceable by our logistics service providers or the
        area is not covered, we will request You to provide us with an alternate
        shipping address. You can make purchases on the App from anywhere in the
        world but at the same time You must ensure the payment and shipping
        address is within India.
      </p>
      <p>
        When You submit an alternative address for delivery, You are responsible
        for accepting and collecting the package from such alternative address.
        Confirmation from the logistics service provider of successful delivery
        to the alternative shipping address is deemed as acceptance of delivery
        by You and TagOn Store will have no liability in this regard.
      </p>
      <p>
        Delivery to Partner Store (subject to the availability of function and
        the facilities by seller)
      </p>
      <p>
        Instead of delivering the order to Your shipping address, You can choose
        to have Your product delivered to a partner store so You can pick-up the
        package from the partner store at Your convenience. On Your collecting
        the package You will receive a notification by email or SMS at the email
        address or mobile number provided to TagOn Store confirming delivery of
        the product. Click here to get a list of our partner stores. If You do
        not collect the product from the partner store within allowed time, the
        product will be returned to the seller and Your order will be
        cancelled. No refund due to non-deliverability will be applicable to
        orders placed with Cash on Delivery options.
      </p>
      <h3 id="delivery-by-tagon-store-or-seller">
        Delivery by TagOn Store or Seller
      </h3>
      <p>
        The delivery of the ordered products from the seller to You will be made
        by logistics service providers assigned by TagOn Store or Sellers.
        However, large and bulky products such as televisions, refrigerators,
        air-conditioners, etc. may be delivered to You directly by the seller.
        Deliveries cannot be rescheduled after the order has been placed.
      </p>
      <p>
        The logistics service provider assigned by TagOn Store will make a
        maximum of three [3] attempts to deliver Your order. In case You are not
        reachable, available or do not accept delivery of products in these
        attempts TagOn Store reserves the right to cancel the order at its
        discretion. You may be informed of such cancellation by email or SMS at
        the email address or mobile number provided to TagOn Store. The
        cancelled order is returned to seller. You agree not to hold TagOn Store
        liable for any cancellation.
      </p>
      <p>
        If You wish to request the App to resend the product, You can do so by
        placing a new order for the product on the terms set out on the
        [&#39;product order page&#39;] when You place the new order. Your order
        will be accepted subject to availability of the product.
      </p>
      <p>
        In case there is any dispute regarding the shipment of the product or
        serviceability for the area not covered by the logistics service
        providers, TagOn Store will not be responsible for the non-delivery of
        the product.
      </p>
      <p>
        Title and risk of loss for all products ordered by You shall pass on to
        You upon delivery of the products at the shipping address provided by
        You.
      </p>
      <h3 id="multiple-product-orders">Multiple Product Orders</h3>
      <p>
        In case You order multiple products in one transaction, TagOn Store
        would endeavour to ship all products together. However, this may not
        always be possible due to some product characteristics or logistics
        issues or the location of seller and You may receive the products
        separately and at different times. If You purchase multiple products in
        a single transaction, then all the products would be dispatched to a
        single shipping address given by You. If You wish to ship products to
        different addresses, then You should book the orders separately based on
        the shipping addresses.
      </p>
      <h3 id="missing-items">Missing Items</h3>
      <p>
        In the event, the product package received by You is missing one or more
        products or accessories or does not include the freebie or the order is
        partly delivered, You should inform TagOn Store through the Customer
        Care Team within 3 days of delivery date of the package. {" "}
        <strong>
          Missing products claims will not be accepted after 3 days from
          delivery date.
        </strong>
      </p>
      <p>
        When a package delivered is missing one or more products or accessories,
        You are requested to return the original package delivered to the seller
        in accordance with the Return Policy.
      </p>
      <p>
        In the event of dispute, TagOn Store will coordinate with You and the
        seller to reach an amicable solution. TagOn Store is only a facilitating
        link between You and the seller and will not be liable in any manner.
      </p>
      <h3 id="refusal-of-delivery">Refusal of Delivery</h3>
      <p>
        Before accepting delivery of any product, kindly ensure that the
        product&#39;s packaging is not damaged or tampered. If You observe that
        the package is damaged or tampered, You are requested to refuse to
        accept delivery and inform TagOn Store within 5 days of delivery date.
      </p>
      <p>
        You are requested to fill the delivery sheet / acknowledgement used by
        the logistics service providers and note down the condition of the
        delivered package. Damaged or tampered products claims will not be
        accepted beyond the returns policy.
      </p>
      <p>
        In the event of dispute, TagOn Store will coordinate with You and the
        seller to reach an amicable solution. TagOn Store is only a facilitating
        link between You and the seller and will not be liable in any manner.
      </p>
      <h3 id="delivery-time">Delivery Time</h3>
      <p>
        An estimated delivery time of each product will be displayed on the
        [&#39;product order page&#39;]. On placing Your order, You will receive
        an email or SMS at the email address or mobile number provided to TagOn
        Store containing a summary of the order and also the estimated delivery
        time to Your location. The seller may take additional time to pack and
        dispatch certain products and this time will be indicated by the message
        on the [&#39;product order page&#39;]. This period of time is to be
        added to the shipping speed You choose.
      </p>
      <p>
        Delivery timeframes are just estimates and are not guaranteed delivery
        timeframes and should not be relied upon as such. Deliveries to certain
        locations may take longer than expected due to accessibility of the
        location and serviceability by the logistics service provider.
      </p>
      <h3 id="about-delayed-delivery">About Delayed Delivery</h3>
      <p>Sometimes, delivery may take longer due to:</p>
      <ul>
        <li>Bad weather.</li>
        <li>Transportation delays.</li>
        <li>Natural calamities.</li>
        <li>Political disruptions.</li>
        <li>Logistics service provider related challenges.</li>
        <li>Product lost in transit.</li>
        <li>
          Regional or national holidays which are considered as delivery
          holidays.
        </li>
        <li>
          Other unforeseen circumstances or events beyond the control of TagOn
          Store or logistics service provider.
        </li>
      </ul>
      <p>
        If the estimated delivery date has passed and You still have not
        received Your order, please contact us and we will take steps to track
        Your package. In such cases, we may also try to proactively contact You.
        Please check Your emails and SMS regularly for such updates.
      </p>
      <p>
        TagOn Store will not compensate for any mental agony or inconvenience,
        or loss caused due to delay in delivery for any reason. You may be
        informed by email or SMS at the email address or mobile number provided
        to TagOn Store if any product in the order is unavailable or is delayed
        or lost in transit.
      </p>
      <h3 id="processing-fee">Processing Fee</h3>
      <p>
        Processing Fee is universally applicable to all our valued customers and
        includes the Platform Fees, Shipping Fees, and Packaging Fees which are
        inclusive of GST unless stated otherwise.
      </p>
      <p>
        Platform Fees is charged by TagOn Store for the services provided on the
        platform, which includes without limitation, access to the platform
        services, brand liaison, and comprehensive customer support.
      </p>
      <p>
        Shipping Fees may vary for different pin codes because of the increase
        in shipping cost based on location of sellers or seller&#39;s is located
        in a different areas. Delivery charges will vary according to pin codes
        as well as type of product.
      </p>
      <h3 id="open-delivery">Open delivery</h3>
      <p>
        TagOn Store reserves the right to provide Open delivery or not. Open
        delivery service which is provided by TagOn Store with some Specifics
        logistics service providers. Open delivery depends upon our logistics
        service provider&#39;s service dependencies. {" "}
        <strong>
          You can&#39;t open parcel if logistics service provider doesn&#39;t
          allow and, in such event, You have a right to accept the parcel after
          paying the amount and thereafter You can use our hassle-free return
          policy
        </strong>
        .
      </p>
      <p>
        Please refer to the Return and Refund Policies, mentioned below if You
        wish to return the delivered product to TagOn Store.
      </p>
      <h2 id="return-policy">RETURN POLICY</h2>
      <p>
        Our hassle-free Return Policy ensures that you can easily return any
        product in the event of damage, defective, significantly different from
        what you ordered, or if the package is missing any products or
        accessories.
      </p>
      <h3 id="conditions-for-return-">Conditions for return : </h3>
      <p>
        In order to return any product sold through the Website / App, You are
        required to comply with the below mentioned conditions, viz. :   
      </p>
      <ul>
        <li>
          Products should be returned unused, in their original packaging along
          with the original price tags, labels, packing, barcodes, user manual,
          warranty card and invoices, accessories, freebies and original boxes
          defined as essentials.    
        </li>
        <li>
          If a product is returned without the essentials, it will not be
          accepted for return and will be returned to you. If the logistics
          partner validates the same at your door, he may refuse to pick up the
          product because these are required details to be shipped along with
          the product, and Pentagon Tapes Private Limited reserves the right to
          refuse any such returns in the absence of a complete product not
          handed over to the logistics partner.   
        </li>
        <li>
          The return packages should be strongly and adequately packaged so that
          there is no damage of products during transit.   
        </li>
        <li>
          The returned products are subject to verification and quality checks
          by the sellers selling on Pentagon Tapes Private Limited in order to
          determine the legitimacy of the complaint or return.   
        </li>
        <li>
          If You have chosen the partner store option for delivery of the order
          and wish to return the product, You should return the product to the
          same store from where You collected the order or to other store that
          the partner might allow from time to time. You should return the
          product in original and unused condition along with all original price
          tags, labels, packing, barcodes, user manuals, warranty card,
          accessories, freebies, and boxes received. The Store reserves the
          right to accept the product after quality check.   
        </li>
        <li>
          The return must be in compliance with the return policy of the seller
          or the manufacturer of the product where applicable. 
        </li>
      </ul>
      <h3 id="non-returnable-products-">Non-returnable products : </h3>
      <p>
        Pentagon Tapes Private Limited should be notified of the receipt of a
        damaged or defective product or if the product is significantly
        different from what was purchased or the package delivered is missing
        one or more products or accessories, within period mentioned in the
        returns policy. 
      </p>
      <p>
        If You are unable to inform Pentagon Tapes Private Limited of the
        receipt of a damaged or defective product or the product is not as per
        specifications of Your order or the package delivered is missing one or
        more products or accessories as per the returns policy and procedure,
        Pentagon Tapes Private Limited or Seller shall not be obliged to accept
        the returned product or be liable in this regard. In the event such
        product is sent to Seller, the product will be returned to customer.
        Pentagon Tapes Private Limited or seller will not be responsible if the
        product is not accepted, and no refund will be issued. 
      </p>
      <p>
        Returns of Non-returnable products are only accepted if they are
        damaged, defective, or not received as described : 
      </p>
      <ul>
        <li>
          <p>
            In the event You receive a damaged or defective product or a product
            that does not comply with the specifications as per Your original
            product or the package delivered is missing one or more products or
            accessories and eligible for return as described by Return Policy,
            You are required to get in touch with our Customer Care Team at
            9987706817 between 10:00 a.m. and 06:00 p.m., five days a week.
          </p>
        </li>
        <li>
          <p>
            Upon receiving Your return request, Pentagon Tapes Private Limited
            will verify the authenticity and nature of the request. In certain
            scenarios, we may request images of the product and packaging to
            validate the reason for the return. Pentagon Tapes Private Limited
            will inform the seller of the return request and will ask the seller
            for a refund to be processed upon the receipt and quality check of
            the returned product. It will take a minimum of 4 - 6 business days
            to process Your request for return of products.   
          </p>
        </li>
        <li>
          <p>
            Pentagon Tapes Private Limited or the seller will arrange for the
            product pick-up through a designated logistics service provider. We
            will send you an email, SMS, or notification to the email address or
            mobile number you provided to Pentagon Tapes Private Limited. Please
            note that if we are unable to pick up your order within three
            attempts, the return will be cancelled.
          </p>
        </li>
        <li>
          <p>
            Upon receipt of the returned product by the seller and successful
            completion of the quality check, You will receive an email or SMS
            confirmation at the email address or mobile number provided to
            Pentagon Tapes Private Limited.     You expressly acknowledge that
            the seller is solely responsible for the damaged or defective
            product or a product that does not meet the specifications of Your
            original order or delayed delivery of the order or delivery of the
            package with one or more products or accessories missing and for any
            claims that You may have in relation to such products and Pentagon
            Tapes Private Limited shall not in any manner be held liable for the
            same. In the event of dispute, Pentagon Tapes Private Limited will
            coordinate with You and the seller to reach an amicable solution.
            Pentagon Tapes Private Limited is only a facilitating link and an
            Intermediary as per definition under Information Technology Act,
            2000 and rules framed thereunder, between You and the seller and
            Pentagon Tapes Private Limited will not be liable in any manner. 
          </p>
        </li>
      </ul>
      <h3 id="dead-on-arrival-products-">Dead on arrival products :</h3>
      <p>
        In the event You receive an electronic or technological product that is
        has manufacturing defect or is dead on arrival, You should inform
        Pentagon Tapes Private Limited the period defined in the returns policy
        and place a request for return by following the procedure defined in the
        Returns Policy. You may be asked to obtain a manufacturer defect or
        Dead-on Arrival certificate from the manufacturer or an authorised
        service center of the manufacturer. This certificate will have to be
        produced to Pentagon Tapes Private Limited for processing Your return
        request and sent to seller. Pentagon Tapes Private Limited may engage
        with a third party at its own discretion to help Customers obtain this
        certificate and cannot be held responsible for any delay or mishandling
        of products. If the products are found to be working or the defect is
        caused to any mishandling, product will be returned back to You.
        Customers are required to accept the return. 
      </p>
      <p>
        In case a dead-on arrival certificate cannot be issued by an authorised
        service center of the manufacturer, You will be required to get the
        product serviced or repaired as per warranty provided by the
        manufacturer and Pentagon Tapes Private Limited will not be liable in
        any manner. In the event the certificate provided as dead-on arrival
        certificate is disputed by the manufacturer or deemed as invalid, the
        product will be returned to You and Pentagon Tapes Private Limited will
        not be liable in any manner. 
      </p>
      <h3 id="period-for-return-">Period for return : </h3>
      <p>
        The return must be in compliance with the Return Policy associated with
        the product category. In general, product return timelines range from 0
        to 15 days; however,{" "}
        <strong>
          we recommend that you visit the product page to find most recent
          return period.
        </strong>{" "}
         
      </p>
      <table>
        <thead>
          <tr>
            <th>
              <strong>Categories</strong>
            </th>
            <th>
              <strong>Return Window</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Apparels, Footwear, Kids Clothing, Accessories</td>
            <td>15 days</td>
          </tr>
          <tr>
            <td>Home</td>
            <td>10 days</td>
          </tr>
          <tr>
            <td>
              Formal suits-Mens, Ethnic Suits-Mens, Sherwani, Watches, Eyewear,
              Furniture, Jewellery
            </td>
            <td>7 days</td>
          </tr>
        </tbody>
      </table>
      <p>
        The following table contains a list of products that are not eligible
        for returns from the above listed category:
      </p>
      <table>
        <thead>
          <tr>
            <th>
              <strong>Categories</strong>
            </th>
            <th>
              <strong>Some product excluded from returns</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Apparel</td>
            <td>
              Bottom wear, Boxers, Briefs, Innerwear, Panties, Rain Wear,
              Shapewear, Sleepwear, Socks, Stockings &amp; Tights, Swimwear,
              Thermals, Trunks, Vests
            </td>
          </tr>
          <tr>
            <td>Sports &amp; Fitness Equipment&#39;s, Footwear Accessories</td>
            <td>
              All products from Sports &amp; Fitness equipment&#39;s including
              Footwear Accessories are non-returnable
            </td>
          </tr>
          <tr>
            <td>Jewellery</td>
            <td>Coins, Bars, Stones, Nose rings and Nose pins</td>
          </tr>
          <tr>
            <td>Electronics, Beauty</td>
            <td>All products from Beauty and Electronics are non-returnable</td>
          </tr>
          <tr>
            <td>Watches</td>
            <td>
              Smartwatches, digital watches, and all products from the TISSOT
              brand
            </td>
          </tr>
          <tr>
            <td>Eyewear</td>
            <td>
              Ray-Ban, Oakley, Polaroid, Carrera, Guess, Tommy Hilfiger, DIESEL,
              Fossil, Vogue Eyewear, Calvin Klein, Timberland, Police, LACOSTE,
              Hugo, SCOTT, Nautica, Kenneth Cole, Levi&#39;s, Chilli Beans, WEB
              EYEWEAR, Calvin Klein Jeans, CAROLINA HERRERA, Hidesign, Maui Jim,
              Dsquared2, Daniel Wellington, HUGO BOSS, Fila, CHOPARD, bebe,
              Under Armour, MOLSION, VICTORIA&#39;S SECRET, BOLON, Pierre
              Cardin, SWAROVSKI, Boss Orange, JUICY COUTURE, Havaianas
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        The below table outlines the exclusive brands for women&#39;s apparel,
        with a specified return window of 7 days.
      </p>
      <table>
        <thead>
          <tr>
            <th>
              <strong>Categories</strong>
            </th>
            <th>
              <strong>Brands</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Women&#39;s Apparel</td>
            <td>
              Ahalyaa, Biba, Indo Era, Juniper, Rustorange, SCAKHI, Soch,
              Spykar, true Browns, BOSSINI, Code by Lifestyle, Colour Me by
              Melange, Cottinfab, Fame Forever by Lifestyle, Forever New, Ginger
              by Lifestyle, KAPPA, Kazo, KZ07 by Kazo, Melange by Lifestyle,
              SmileyWorld, Aks, Aks Couture, Fabindia, Janasya, W, AARKE RITU
              KUMAR, Label Ritu Kumar, Levi&#39;s, AND, Global Desi, Ancestry ,
              Cover Story, Twenty Dresses, RSVP, Anvi Be Yourself, Femella,
              Fable Street , Pink Fort, Juelle, Mafadeny
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        A request for return once made can be cancelled through app/website from
        &quot;My orders&quot; section. In case the Logistics Service provider
        arrives to receive the shipment and You want to cancel the request, You
        may choose to inform the logistics service provider that You do not wish
        to return the product. You will receive an email or SMS notification at
        the email address or mobile number provided to Pentagon Tapes Private
        Limited. Please note that if we are unable to pick up your order within
        three attempts, the return will be cancelled.
      </p>
      <h3 id="refusal-of-return-request-">Refusal of return request : </h3>
      <p>
        Pentagon Tapes Private Limited reserves the right to refuse or cancel
        any return request.  If the request for returns is not allowed by the
        Returns Policy, You will not be refunded the payment made or any costs
        and will not be able to raise a second request for return for the same
        product. You agree not to dispute the decision made by Pentagon Tapes
        Private Limited and accept Pentagon Tapes Private Limited&#39;s decision
        regarding the refusal or cancellation and further agree not hold
        Pentagon Tapes Private Limited liable for any refusal or cancellation. 
      </p>
      <h3 id="frivolous-complaints-">Frivolous complaints : </h3>
      <p>
        In the event of frivolous or baseless complaints or requests regarding
        the quality or content of the products, Pentagon Tapes Private Limited
        reserves the right to take necessary legal actions against You and You
        will be solely liable for all costs incurred by Pentagon Tapes Private
        Limited in this regard. 
      </p>
      <h3 id="return-shipping-process-">Return Shipping Process : </h3>
      <p>
        In case of damaged claims, Pentagon Tapes Private Limited may ask for
        pictures of the damaged product before it is approved or allowed for
        return. Once the return is approved, a full refund, including any
        applicable Processing fees, will be refunded.
      </p>
      <p>
        For return shipping managed by Pentagon Tapes Private Limited, You will
        need to hand over the product to the assigned logistics service provider
        at the time of pick-up. In the event the logistics service provider
        makes attempts to pick-up the product and You are unavailable or not
        ready to handover the product, Pentagon Tapes Private Limited or
        logistics service provider will not be held responsible for the delay in
        pick-up or processing of the quality check by the seller and hence the
        refund. A maximum of 3 attempts will be made by the logistics service
        provider to pick-up the product to be returned. 
      </p>
      <p>
        Pentagon Tapes Private Limited will not be liable for the products
        returned by mistake. 
      </p>
      <p>
        In the event where a product not belonging to the seller selling on
        Pentagon Tapes Private Limited is returned by mistake, Pentagon Tapes
        Private Limited is not accountable for such misplacement and is not
        responsible for its delivery back to You or any costs incurred by You or
        any refund. 
      </p>
      <h3 id="discrepancy-between-you-and-our-logistics-service-provider-">
        Discrepancy between You and our logistics service provider : 
      </h3>
      <p>
        In case of any discrepancy in the status of pick-up of a product
        arranged by Pentagon Tapes Private Limited, (where You claim the product
        has been returned, while our system suggests otherwise) refund will be
        initiated only if You successfully furnish the courier slip given by the
        Pentagon Tapes Private Limited assigned logistics service provider at
        the time of the pick-up. 
      </p>
      <p>
        In case where a seller or designated return location claims that the
        product received from You is different or the return of the product is
        not in compliance with the Returns Policy, the product will be
        dispatched back to Your shipping address, and You are required to
        receive and accept the package. In the event of dispute, Pentagon Tapes
        Private Limited will coordinate with You and the seller to reach an
        amicable solution. Pentagon Tapes Private Limited is only a facilitating
        link between You and the seller and will not be liable in any manner. 
      </p>
      <h2 id="cancellation-policy-">Cancellation Policy </h2>
      <p>
        Cancellation of an order can be either by Pentagon Tapes Private Limited
        or by You. 
      </p>
      <h3 id="cancellation-by-us-">Cancellation by us : </h3>
      <p>
        Pentagon Tapes Private Limited has the discretion to cancel an order.
        Some of the reasons for cancellation are as under (not being exhaustive
        list of reasons) : 
      </p>
      <ul>
        <li>Failure to comply with any of the Terms. </li>
        <li>Product is unavailable with the seller. </li>
        <li>Technical errors or issues. </li>
        <li>
          Problems identified by Pentagon Tapes Private Limited&#39;s credit and
          fraud avoidance department. 
        </li>
        <li>Invalid address is given in order details. </li>
        <li>Malpractice is used to place the order. </li>
        <li>Bulk order is placed for commercial resale. </li>
        <li>
          Multiple orders are placed for same product at the same address. 
        </li>
        <li>Order is undelivered after 3 attempts. </li>
        <li>
          Failure to collect the order from partner store within stipulated
          time. 
        </li>
      </ul>
      <p>
        Pentagon Tapes Private Limited reserves the right, at its sole
        discretion, to refuse or cancel any order for any reason whatsoever. On
        such cancellation You will be sent a notification email or SMS at the
        email address or mobile number provided to Pentagon Tapes Private
        Limited. 
      </p>
      <h3 id="cancellation-by-you-">Cancellation by You: </h3>
      <p>
        You may cancel an order for any reason, although cancellation by You
        must be done before the product has been shipped for delivery. No
        cancellation is permitted subsequently. 
      </p>
      <p>
        You can cancel an order, or part of an order where multiple products
        have been ordered. This can be done either by calling or emailing
        Customer Care Team or from Your account on the Website / App through
        &#39;my orders&#39; section. You should state the reason for such
        cancellation. Pentagon Tapes Private Limited will process the request
        for cancellation as per the policy in force. 
      </p>
      <p>
        If an order has been successfully cancelled, You will be sent a
        confirmation email or SMS at the email address or mobile number provided
        to Pentagon Tapes Private Limited. 
      </p>
      <p>
        Pentagon Tapes Private Limited reserves the right to accept or reject
        requests for order cancellations for any reason whatsoever. You agree
        not to hold Pentagon Tapes Private Limited liable for any rejection of
        order cancellations. 
      </p>
      <p>
        You will receive a refund for Your cancelled orders that have been
        accepted in accordance with the Refund Policy. 
      </p>
      <h2 id="refund-policy-">Refund Policy </h2>
      <h3 id="refunds-">Refunds   </h3>
      <ul>
        <li>
          Refunds are provided for cancellation/return of products, subject to
          the eligible cancellation/ return as per Return Policy or these terms
          and conditions, initiated in accordance with the Return or
          Cancellation Policy. 
        </li>
        <li>
          In case of cancellations a refund for the entire order amount,
          including Processing Fee, will be issued.
        </li>
        <li>
          For orders being returned after delivery to customer, we will not be
          able to refund the Processing Fee which includes Platform Fees,
          Shipping Fees and Packaging Fees.
        </li>
        <li>
          Once the order is shipped, in the event of any returns before
          accepting delivery or undelivered orders, a refund will be processed
          for the product amount only, excluding the Processing Fee.
        </li>
        <li>
          For accounts whose return behaviour violates our fair usage policy,
          Processing fees will be non-refundable irrespective of order value. 
        </li>
        <li>
          Please note, Pentagon Tapes Private Limited initiates the refund upon
          successful return pick up, or after the returned item has reached the
          Seller and quality check is successful (Either from Seller or at
          Doorstep). Therefore, the refund initiation time may vary by the time
          taken courier partner to deliver the return to Pentagon Tapes Private
          Limited/ Seller. 
        </li>
        <li>
          If any refund is received by You pending the quality checks, You will
          hold such amounts in trust on behalf of Pentagon Tapes Private Limited
          until conclusion of such quality checks unless claimed by Pentagon
          Tapes Private Limited any time before that. If any amounts are wrongly
          paid or refunded to You, You will transfer such amounts back to
          Pentagon Tapes Private Limited within 3 days of Your receipt.  
        </li>
        <li>
          No refund due to non-deliverability will be applicable to orders
          placed with Cash on Delivery options.  
        </li>
      </ul>
      <h3 id="time-period-for-refunds-">Time period for refunds : </h3>
      <p>
        After Pentagon Tapes Private Limited initiates the refund in accordance
        with the Returns Policy, the refund amount is expected to reflect in the
        customer account within the following timeframes: 
      </p>
      <ul>
        <li>Wallet: Instant </li>
        <li>
          Online Refund: 3 to 6 working days post refund initiation, depending
          on your bank partner. 
        </li>
      </ul>
      <p>
        For some public sector banks, it could take up to 8–10 working days for
        the amount to reflect in your source account. 
      </p>
      <p>
        Pentagon Tapes Private Limited is not responsible for any errors or
        delays in refund due to banks or third-party service provider errors or
        delays. 
      </p>
      <h3 id="mode-of-refund-">Mode of refund : </h3>
      <p>
        The mode of refund of payments cannot be changed at any stage as the
        refund amount is transferred to Your source account. Refunds are paid
        back to the source of payment. 
      </p>
      <p>
        If a refund for a payment method other than Cash on Delivery fails to be
        processed back to the source, we will issue a refund that must be
        redeemed by you in order to receive an instant refund.  
      </p>
      <p>
        You will need to update the bank account number and IFSC code to enable
        us to process a refund to Your account. Refunds cannot be processed to
        third-party accounts, i.e., the name on Your Pentagon Tapes Private
        Limited account should match with the name of the bank account holder
        provided for refund via NEFT. A refund initiation confirmation by email
        or SMS at the email address or mobile number provided to Pentagon Tapes
        Private Limited will be sent to You.
      </p>
      <h2 id="offer-for-sale">OFFER FOR SALE</h2>
      <h3 id="listing-of-products">Listing of Products</h3>
      <p>
        TagOn Store through the App makes available a variety of branded
        products and merchandise of the sellers for sale to buyers. Sellers who
        have agreed to sell their products on the App list their products on the
        App. Purchase and sale of such products will be subject to these Terms.
      </p>
      <p>
        All product and price information, deals and offers related to the
        products are provided by the seller to TagOn Store for inclusion on the
        App and the sellers are solely responsible for the accuracy of the
        information, validity of the prices, charges, deals, offers and other
        information relating to the products offered on the App. Separate
        contracts govern the relationship between TagOn Store and sellers for
        display and sale of their products on the App. These Terms do not govern
        and are not applicable to (and do not amend or modify) the relationship
        and arrangement between TagOn Store and such sellers as set forth in
        such separate contracts.
      </p>
      <p>
        TagOn Store does not endorse or warrant the quality, quantity,
        stability, use, price or other specifications of the products offered
        for sale by the sellers and purchased by buyers on the App or for any
        defects, deficiencies, shortcomings, errors or omissions in relation to
        the products. Please make sure You have read the product listing
        information before placing an order.
      </p>
      <h3 id="ordering">Ordering</h3>
      <p>
        It is not compulsory for You to have an account on the App to buy the
        products. You can shop by using a valid email address, mobile number and
        details while placing orders. You will be informed of the receipt of the
        order by email or SMS on the email address or the mobile number provided
        by You to TagOn Store.
      </p>
      <p>
        You can select any product or seller of Your choice from the
        [&#39;product page&#39;] of the App and add it to the shopping cart or
        wish list. Placing a product in the shopping cart or wish list does not
        reserve the price, quantity promotional offers or shipping charges shown
        at a particular time. To purchase any product in the shopping cart, You
        should provide the shipping address within India and make payment of the
        product price or choose payment on delivery as per the payment method
        for eligible products.
      </p>
      <p>
        You can also call or email the Customer Care Team to place an order for
        products eligible for payment on delivery.
      </p>
      <p>
        Once the App receives a confirmation of Your order from the seller and
        confirms Your order, You will receive an email and SMS informing You of
        the order confirmation. The email will also provide You with details of
        Your order including order ID, information on the products ordered, as
        well as the expected delivery time.
      </p>
      <p>
        You understand that by ordering a product using the App, You are
        entering into a binding contract with the seller of the product to
        purchase the product using the facilities available on the App.
      </p>
      <p>
        You agree that the seller is the owner of the products and all the
        ownership rights and property of, in and upon the products listed on the
        App, until transferred to the buyer of the products, shall vest in the
        seller of the products. The seller confirms and undertakes the
        seller&#39;s ownership of the products and confirms that the seller
        shall at all times have marketable and legal ownership and title in
        respect of all products offered on the App and shall pass on marketable
        and legal ownership and title of the product to You upon completion of
        the transaction.
      </p>
      <p>
        The buyer and seller acknowledge that the Terms of the App do not cover
        products sold by the seller and bought by the buyer from the seller
        other than through the App. You should not try to contact other
        potential buyers to warn them about one or more sellers. If You have a
        concern about a seller&#39;s behaviour, please report it to our Customer
        Care Team and we will endeavour to investigate.
      </p>
      <p>
        You confirm that the products ordered by You are purchased for Your
        internal or personal use and not for re-sale. You authorize the seller
        and the App to declare and provide declarations to any governmental
        authority on Your behalf stating the aforesaid purpose of the products
        ordered by You on the App.
      </p>
      <h3 id="delivery">Delivery</h3>
      <p>
        The seller delivers the ordered products either directly or by availing
        the services of the App through its logistics service providers in terms
        of the Delivery Policy. The App may change the Delivery Policy without
        any notice. For more details, please review the Delivery Policy.
      </p>
      <h3 id="pricing-and-payment">Pricing and Payment</h3>
      <p>
        The App lists availability and pricing information of products on the
        product display pages of the App.
      </p>
      <p>
        All prices are inclusive of GST unless stated otherwise. Products in
        Your shopping cart will always reflect the most recent price displayed
        on the product display page. Placing a product in the shopping cart or
        wish list does not reserve the price shown at a particular time. It is
        possible that a product&#39;s price may decrease or increase between the
        time the product is placed in the shopping cart or wish list and the
        time You actually purchase it. Products that are marked as &#39;Out of
        Stock&#39; are not available for purchase.
      </p>
      <p>
        The price of products offered by the seller on the App is either equal
        to or lesser than the Maximum Retail Price (&quot; <strong>MRP</strong>
        &quot;) i.e., the discounted rate prescribed for those products. The MRP
        and other statutory declarations will be mentioned on the products or on
        its packaging in accordance with applicable laws. Sellers may or may not
        specify this information on the App, and You are advised to
        independently determine the MRP of such products if You so desire.{" "}
        <strong> </strong>
      </p>
      <p>
        The App shall, in relation to the services being provided by it in
        accordance with these Terms, process payments from the buyer of the
        products and manage the logistics in the manner it deems appropriate,
        for and on behalf of the sellers of the products. The seller or TagOn
        Store will arrange logistics for delivery of large and bulky products
        such as televisions, refrigerators, air conditioners etc., to the buyer.
      </p>
      <p>
        By purchasing the products using the App and authorizing the App to
        process the payment, You agree, accept and understand that the App as
        part of its services, is only a facilitator providing an automated
        online electronic payment, receiving payment through cash on delivery,
        collection and remittance facility for the sale and purchase of the
        products.
      </p>
      <p>
        For more details, please review the Payment Policy applicable to the
        products ordered from the sellers. The App may change the Payment Policy
        without any notice.
      </p>
      <h3 id="returns-and-cancellation">Returns and Cancellation</h3>
      <p>
        All products which are not delivered to You or returned by You in
        accordance with the Return Policy will be returned to the seller of the
        products.
      </p>
      <p>
        Return and cancellation of the products may be initiated in accordance
        with the App&#39;s policies. Please review the Returns Policy and
        Cancellation Policy as applicable to the products purchased by You. The
        App may change the Returns Policy and Cancellation Policy without any
        notice.
      </p>
      <h3 id="taxes">Taxes</h3>
      <p>
        You are responsible for payment of all fees, costs and charges
        associated with the purchase of products from the sellers and You agree
        to bear any and all applicable taxes, Value Added Taxes, cesses, etc.
        levied on the products on their sale unless otherwise stated.
      </p>
      <h3 id="no-warranty">No Warranty</h3>
      <p>
        All products or services are made available through the App on an
        &quot;AS IS&quot; basis and &quot;AS AVAILABLE BASIS&quot; without any
        warranty of any kind, express, implied, statutory or otherwise,
        including the implied warranties of title, non-infringement,
        suitability, accuracy, reliability, completeness, timeliness,
        performance, safety, merchantability or fitness for a particular purpose
        or the legality of the products listed or displayed or transacted to the
        extent permitted by applicable law and TagOn Store does not accept any
        liability for any defects, deficiencies, short comings, errors or
        omissions.
      </p>
      <p>
        TagOn Store does not make any representations pertaining to the
        information, content, products or services included on or otherwise made
        available to You through or on the App. The prices, shipping costs,
        payment details, date, period and mode of delivery, warranties related
        to products and after sales services, if any related to products are
        subject to the terms provided by the seller to the buyer. TagOn Store
        does not have any control over such information and plays no
        determinative role in the finalization of the same and hence does not
        stand liable for the outcomes of such terms.
      </p>
      <p>
        The buyer acknowledges that before placing an order for a product the
        buyer has performed a diligence and background check of the sellers of
        the product, their creditworthiness, genuineness of the product and is
        entering into the transaction being aware of these Terms.
      </p>
      <p>
        The buyer acknowledges that the product images are indicative in nature
        and actual product may differ from the image. The buyer further
        acknowledges that services provided by the App are being provided by
        TagOn Store as an independent contractor, and TagOn Store is in no
        manner acting as an agent, partner or joint venture partner of either
        the seller or of a third party.
      </p>
      <p>
        The buyer acknowledges and understands that the buyer cannot claim any
        compensation from TagOn Store, other than refund for the amount paid by
        buyer while ordering, for any reason, not limited to but including: (i)
        if the final product or its quality, quantity or specifications does not
        meet buyer&#39;s expectation; (ii) there is any special, incidental,
        indirect or consequential damage caused to the buyer by the product
        purchased; (iii) opportunities missed by buyer for availing any offers
        or discounts on the App for any reason whatsoever; (iv) delivery delays
        due to any unforeseen circumstances by TagOn Store or its assigned
        logistics service providers; (v) deactivation or expiry of a valid
        voucher, coupon or promo code issued by TagOn Store; (vi) buyer faces
        any issues with logistics service provider&#39;s personnel.
      </p>
      <p>
        Being a marketplace and intermediary (except where title of the product
        is held by TagOn Store), TagOn Store holds no title in the products at
        any time, and sale of any product is a transaction restricted between
        You and the seller of Your choice. The contract of sale is purely a
        bipartite contract between You and the seller and TagOn Store is not
        responsible for any claims arising from such a contract and TagOn Store
        will not be considered a party to any claim or proceeding arising from
        such sale and accordingly, You absolve TagOn Store from any such claim
        by You. In case of any dispute TagOn Store is not obliged to mediate or
        resolve any dispute between buyer and seller. 
      </p>
      <h3 id="communications">Communications</h3>
      <p>
         You will be required to provide a valid email address and phone number
        in order to avail the services provided by the App, including and not
        limited to placing orders for buying the products. By registering Your
        email address and phone number with the App, You consent to be contacted
        by the App via email, phone calls or SMS notifications for any order or
        shipment, delivery, payment or offer related updates or otherwise. The
        App may notify or communicate with You by e-mail, SMS, phone or by
        posting notices on the App or by any other mode of communication.
      </p>
      <h3 id="severability">Severability</h3>
      <p>
        Each of the provisions in these Terms are severable and distinct from
        the others and if at any time one or more such provision is or becomes
        invalid, illegal or unenforceable, the validity, legality and
        enforceability of the remaining provisions of these Terms shall not in
        any way be affected or impaired thereby.
      </p>
      <h3 id="captions">Captions</h3>
      <p>
        The various captions used in these Terms are for convenience purpose
        only and may not be used to interpret the provisions thereof. In case of
        captions and the related text conflicts, the text will prevail.
      </p>
      <h3 id="partial-invalidity">Partial Invalidity</h3>
      <p>
        If any provision of these Terms is held to be illegal, invalid, or
        unenforceable under any present or future applicable law, (i) such
        provision will be fully severable; (ii) these Terms will be construed
        and enforced as if such illegal, invalid, or unenforceable provision had
        never comprised a part hereof; (iii) the remaining provisions of these
        Terms will remain in full force and effect and will not be affected by
        the illegal, invalid, or unenforceable provision or by its severance
        here from.
      </p>
      <h3 id="force-majeure">Force majeure</h3>
      <p>
        Notwithstanding anything contained in these Terms or elsewhere, TagOn
        Store shall not be held responsible for any loss, damage to You if such
        loss, damage is attributable to a Force Majeure Event. A {" "}
        <strong>&quot;Force Majeure Event&quot; </strong> means any event that
        is beyond TagOn Store&#39;s reasonable control and includes, without
        limitation, fire, flood, explosion, acts of God, civil commotion,
        strikes, lock outs or industrial action of any kind, riots,
        insurrection, terrorist attacks, war, acts of government, power failure,
        sabotage, computer hacking, unauthorised access to computer data and
        storage device, system failure, virus, attacks, bugs, computer crashes,
        breach of security and encryption.
      </p>
      <h3 id="disputes">Disputes</h3>
      <p>
        All complaints relating to the products purchased from the App are
        routed through the Customer Care Team of the App. The seller is
        responsible to resolve any complaints regarding the product that may be
        raised by the buyer and other complaints to the satisfaction of the
        buyer. The App is only the facilitating link between the buyer and the
        seller of the product for any complaints in relation to the products
        sold by the seller. All buyer complaints, product complaints and
        transaction failure complaints shall solely be to the seller&#39;s
        account and the seller shall undertake all necessary steps as may be
        required as per the complaint or redressal mechanism of the App to
        resolve the complaints at the seller&#39;s own initiative and cost. The
        App is not in any way responsible for ensuring resolution of the
        complaints and the App shall not in any manner either directly or
        indirectly be liable for any claims or proceedings made by any User in
        relation to the products provided on or through the App or otherwise.
      </p>
      <h3 id="covenants">COVENANTS</h3>
      <p>
        The App is only a platform that can be used by users to reach a
        larger base to buy and sell products. TagOn Store is only providing a
        platform for communication and the contract for sale of the
        products shall be a strictly bipartite contract between the buyer and
        the seller. Except the cases where TagOn Store is the seller, at no time
        shall TagOn Store hold any right, title or interest over the products
        or have any obligations or liabilities in respect of such bipartite
        contract entered into between the buyer and seller.
      </p>
      <p>
        Users of the App may upload and display the images and information of
        their products offered for sale on the App&#39;s online platform. Such
        display of the User&#39;s products or related information on the App in
        no manner can be treated or construed as an endorsement or advertisement
        of such products by TagOn Store.  
      </p>
      <p>
        Provision of a specific service through the online platform of the App
        will be subject to users agreeing and consenting to certain additional
        terms, rules, policies, procedures, guidelines, disclaimers, notices or
        otherwise, if any, as applicable to such service (&quot;{" "}
        <strong>Additional Terms</strong>&quot;) and if there is any conflict
        between the Terms of Use and the Additional Terms, the Additional Terms
        shall take precedence in relation to that specific service.
      </p>
      <p>
        You agree to use the App and the materials provided on the App only for
        purposes that are permitted by these Terms and any applicable law. You
        shall not use or access the App in a manner that prejudicially affects
        the interests and goodwill of the App, TagOn Store or its affiliates or
        associates.
      </p>
      <p>
        You agree and undertakes that the reviews, comments and other
        information provided or posted or uploaded on the App are not false,
        incomplete, distorted, manipulated, fraudulent or misleading.  By way of
        example, and not as a limitation, You agree and undertake that while
        using or accessing the App, You will not host, display, upload, modify,
        transmit, update or share any information (&quot;{" "}
        <strong>Objectionable Content</strong>&quot;) that:
      </p>
      <ul>
        <li>
          belongs to another person and to which You do not have any right to.
        </li>
        <li>
          is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another&#39;s
          privacy, hateful, or racially, ethnically, objectionable or
          disparaging.
        </li>
        <li>
          is relating or encouraging money laundering or gambling or otherwise
          unlawful in any manner whatsoever.
        </li>
        <li>harms minors in any way.</li>
        <li>
          provides material that exploits people in a sexual or otherwise in an
          inappropriate manner or unlawfully threatening or harassing or
          incidences covered under Indecent Representation of Women
          (Prohibition) Act, 1986.
        </li>
        <li>
          infringes any patent, trademark, copyright or other proprietary rights
          - violates any law for the time being in force.
        </li>
        <li>
          deceives or misleads the addressee about the origin of such messages
          or communicates any information, which is grossly offensive or
          menacing in nature.
        </li>
        <li>impersonate another person.</li>
        <li>
          tries to gain unauthorized access or exceeds the access or illegally
          or unethically.
        </li>
        <li>
          contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource.
        </li>
        <li>
          threatens the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognisable offence or
          prevents investigation of any offence or is insulting to any other
          nation.
        </li>
        <li>
          may create any kind of liability for TagOn Store or our affiliates. 
        </li>
      </ul>
      <p>
        TagOn Store disclaims all liabilities arising in relation to such
        Objectionable Content on the App. Any User if exposed to such
        Objectionable Content as aforesaid is requested to immediately report
        the same to the Grievance Officer in the manner provided under these
        Terms of Use and the App will endeavour to remove such content as soon
        as possible after investigation.
      </p>
      <p>
        You shall not use any deep-link, robot, spider or other automatic
        device, program, algorithm or methodology, or any similar or equivalent
        manual process, to access, acquire, copy or monitor any portion of the
        App or its content, or in any way reproduce or circumvent the
        navigational structure or presentation of the App, materials or its
        content, to obtain or attempt to obtain any materials, documents or
        information through any means not specifically made available through
        the App.
      </p>
      <p>
        Sometimes You may not access (or attempt to access) any of the services
        by any means other than through the interface that is provided through
        the App, unless User have been specifically allowed to do so in a
        separate agreement with TagOn Store. You should not do anything that
        disables, overburdens, or impair the proper functioning of the App.
        Users should specifically agree that they will not engage in any
        activity that interferes with or disrupts the services or the servers
        and networks which are connected to the services provided through the
        App.
      </p>
      <p>
        You undertake not to attempt to circumvent or modify any security
        technology or software that is part of the App and agree not to engage
        or assist any other person, to circumvent or modify any security
        technology or software that is part of the App or try not obscure or
        obstruct any features of the App. You undertake not to copy, modify,
        create a derivative work of, reverse engineer, decompile or otherwise
        attempt to extract the source code of the App or any part thereof
        without a specific written permission from TagOn Store to do so.
      </p>
      <p>
        The App lists multiple products for sale offered by sellers and hosts
        comments of numerous users and it is not possible for TagOn Store to be
        aware of the contents of each product listed for sale, or each comment
        or review that is displayed. You acknowledge and agree that by accessing
        or using the App or availing the services, User may be exposed to
        content that the User may consider offensive, indecent or otherwise
        objectionable.
      </p>
      <p>
        TagOn Store is not the seller of the products and TagOn Store shall in
        no manner be deemed to be the seller of the products on the App. TagOn
        Store is only facilitating purchase of the products by the User from the
        vendors by providing use and access to the App.
      </p>
      <p>
        You explicitly and expressly consent to receive communications from
        TagOn Store in relation to Your use and access of the App and its
        services and agree that such communications shall not be treated as an
        unsolicited communication.
      </p>
      <p>
        If App is used by You from outside India or countries listed in App, it
        is entirely at Your risk. TagOn Store makes no representation that its
        contents and other features are available or otherwise suitable for use
        outside India or countries listed in App. If the App is used and
        accessed from or in locations outside India or countries listed in App,
        You shall ensure that the usage of the App is legal and valid under
        applicable law of the User&#39;s location. You agree to undertake all
        liabilities, responsibilities and consequences under applicable law in
        connection with the use of the App from or in any location outside India
        or countries listed in App. You acknowledge that for accessing the App
        from any location outside India or countries listed in App, TagOn Store
        may ask You to enter into separate or additional terms as may be
        required from time to time. 
      </p>
      <h2 id="infrastructure-">
        INFRASTRUCTURE                                                   
      </h2>
      <p>
        To use the App, You require Internet connectivity or appropriate
        telecommunication links and the App&#39;s service does not include the
        provision of any computer system, resource, terminal, server, equipment,
        mobile or any devices. TagOn Store shall not have any responsibility or
        liability for any computer system, resource, terminal, server,
        equipment, mobile or any devices necessary for User to use and access
        the App nor shall be liable for any costs incurred on account of Your
        usage of Internet or appropriate telecommunication links usage. TagOn
        Store would not be liable for damages, viruses or other code that may
        affect the Your computer system, resource, terminal, server, equipment,
        mobile or any other devices while using the App and TagOn Store does not
        warrant that the App will be compatible with all hardware and software
        used by the User. You must comply with applicable third party terms, if
        any, such as mobile device agreement or wireless data service agreement
        etc. while using the App. 
      </p>
      <h2 id="use-of-content-">USE OF CONTENT </h2>
      <p>
        You grant TagOn Store and its affiliates and associates a non-exclusive,
        royalty-free, irrevocable, perpetual and fully sub-licensable rights in
        any content that is posted by You or material submitted by You, to use,
        reproduce, publish, distribute and display such content throughout the
        world in any mode medium or manner now known or developed in future; and
        the right to use Your name in connection with such content.
      </p>
      <p>
        You agree that the rights granted as above are irrevocable during the
        entire period of protection of the User&#39;s intellectual property
        rights associated with such content and material. The User agrees to
        waive the User&#39;s right to be identified as the author of
        such content and materials and the User&#39;s right to object to
        derogatory treatment of such content or material. The User agrees to
        perform all further acts and execute deeds and documents necessary to
        perfect the above rights granted by the User to TagOn Store.
      </p>
      <p>
        The User represents and warrants that the User owns or otherwise
        controls all of the rights to the content and material that the User
        posts or that the User otherwise provides on or through the App; that,
        as at the date that the content or material is submitted to the App: (i)
        the content and material is accurate; (ii) use of the content and
        material does not breach any applicable Indian law or the App&#39;s
        policies or guidelines or Additional Terms (iii) the content and
        material do not breach these Terms and Privacy Policy.
      </p>
      <h2 id="third-party-websites-">THIRD PARTY WEBSITES </h2>
      <p>
        The App may contain links to other websites and the App are not in any
        way responsible for the terms of use or content of such websites and
        expressly disclaims all liability associated with the content and use of
        these websites. The linked websites are not under the control of TagOn
        Store and You are encouraged to read the terms of use and privacy policy
        of each and every linked website before accessing any of the third-party
        websites linked to the App and You acknowledge that any risk associated
        thereof while accessing and using such linked third-party websites
        solely lies with You and TagOn Store shall not be responsible in any
        manner whatsoever. 
      </p>
      <h2 id="intellectual-property-rights-">INTELLECTUAL PROPERTY RIGHTS </h2>
      <p>
        All content included on the App, such as text, graphics, logos, button
        icons, images, audio clips, digital downloads, data compilations, and
        software, is the property of TagOn Store, its affiliates, associates or
        its content suppliers and is protected by Indian/ International
        copyright, authors&#39; rights and database right laws. The compilation
        of all content on the App is the exclusive property of TagOn Store, its
        affiliates, associates or its content suppliers and is protected by laws
        of Indian/ International copyright and database right laws. All software
        used on this App is the property of TagOn Store, its affiliates,
        associates or its software suppliers and is protected by laws of India
        and international copyright authors rights and database laws.
      </p>
      <p>
        TagOn Store grants You a limited licence to access and make personal use
        of this App, but not to download (other than page caching) or modify it,
        or any portion of it, except with express written consent of TagOn
        Store. This licence does not include any resale or commercial use of the
        contents of the App; any collection and use of any product listings,
        descriptions, or prices; any derivative use of this App or its contents;
        any downloading or copying of account information for the benefit of
        another seller.
      </p>
      <p>
        The trademarks, logos and service marks displayed on the App (&quot;{" "}
        <strong>Marks</strong>&quot;) are the properties of Pentagon Tapes
        Private Limited or its affiliates, associates or its content suppliers
        or users or respective third parties. Users are not permitted to use the
        Marks without the prior consent of Pentagon Tapes Private Limited, its
        affiliates, associates or its content suppliers or users or the third
        party that may own the Marks.
      </p>
      <p>
        Pentagon Tapes Private Limited owns all intellectual property rights to
        and into the trademark &quot;PENTAGON,&quot; and the App, including,
        without limitation, any and all rights, title and interest in and to
        copyright, related rights, patents, utility models, designs, know-how,
        trade secrets and inventions, goodwill, source code, meta tags,
        databases, text, content, graphics, icons, and hyperlinks.
      </p>
      <p>
        You agree not to use or apply for registration of any marks, or domain
        names which are similar to the marks or domain names used in connection
        with the App or owned by Pentagon Tapes Private Limited.
      </p>
      <p>
        You shall not use, copy, reproduce, modify, alter, change, amend,
        transmit, broadcast, edit, revise, review, adapt, translate, distribute,
        perform, display, sell or otherwise deal with  content or the
        intellectual property rights of the App or content suppliers or third
        parties in any mode medium or manner now known or developed in future
        without authorization from TagOn Store and on happening of any such
        event TagOn Store reserves the right to immediately discontinue the App
        services to such User without prejudice TagOn Store&#39;s right to
        initiate legal action in this regard. You are strictly prohibited from
        framing or use framing techniques to enclose any content or intellectual
        property on the App to illegally and unlawfully exploit the content or
        intellectual property rights owned by TagOn Store or content suppliers
        or third party as the case is.
      </p>
      <h2 id="trademark-infringement-complaints">
        Trademark Infringement Complaints
      </h2>
      <p>
        TagOn Store or Pentagon Tapes Private Limited fully respects
        Intellectual Property (IP) Rights of the IP owners. In case You feel
        that Your Trademark has been infringed, You may write us at 
        <a href="mailto:admin@tagonstore.com">admin@tagonstore.com</a>
         with the subject line as &quot;Trademark Infringement Complaint&quot;
        with complete details viz. Your name and contact address, particulars of
        Your IP and products listed on App allegedly infringing Your IP,  
      </p>
      <h2 id="fee-">FEE  </h2>
      <p>
        Users can use and access the App free of charge. However, at any time in
        future, after duly notifying in advance, TagOn Store reserves its right
        to charge the users for the use of the App and provisioning of the
        services through the App as per applicable Indian law.
      </p>
      <h2 id="notice-procedure-">NOTICE PROCEDURE  </h2>
      <p>
        If You believes that any Objectionable Content is hosted on the App or
        Your rights are being infringed, users shall immediately inform the
        Grievance Officer by following the App&#39;s Notice Procedure mentioned
        below. The App will endeavour to remove such content as soon as possible
        after investigation.
      </p>
      <p>
        No untrue or false information or allegation shall be made by You and if
        found misusing the notifying procedure, TagOn Store reserves the right
        to terminate Your account and reserves the right to initiate appropriate
        legal action in this regard.
      </p>
      <p>
        Upon receipt of a notice, TagOn Store will take appropriate steps for
        removing the infringing or Objectionable Content all of which are taken
        without any admission as to liability and without prejudice to any
        rights, remedies or defences, all of which are expressly reserved. TagOn
        Store shall also endeavour to forward a copy of the notice to the
        alleged infringer or defaulter. Furthermore, in submitting a notice, the
        User grants to TagOn Store the right to use, reproduce, publish, and
        display its content throughout the world in any mode medium or manner
        now known or developed in future. This includes forwarding the notice to
        the parties involved in the provision of the allegedly infringing
        content. If found defaulting You, agree to indemnify TagOn Store for all
        claims brought by a third party against TagOn Store arising out of or in
        connection with the submission of a notice.
      </p>
      <p>
        It is repeated and reiterated that TagOn Store&#39;s role and
        responsibility is completed on taking down the infringing or
        Objectionable Content as alleged in the notice and TagOn Store shall in
        no manner be liable for or in relation to the infringing or
        Objectionable Content. TagOn Store shall in no manner be involved in
        trying, contesting, mediating, negotiating or conciliating the dispute
        or differences between the person who notifies the objections and the
        User against whom the objections are raised. 
      </p>
      <h2 id="disclaimer">DISCLAIMER</h2>
      <p>
        You, being User of the App expressly agree that the use of the App is at
        Your sole risk. The App and the contents uploaded or provide or shared
        by other users are made available to the User through the App on an
        &quot;AS IS&quot; basis and &quot;AS AVAILABLE BASIS&quot; without any
        warranty of any kind, express, implied, statutory or otherwise,
        including the implied warranties of title, non-infringement,
        suitability, accuracy, reliability, completeness, timeliness,
        performance, safety, merchantability or fitness for a particular purpose
        or the legality of the products listed or displayed or transacted to the
        extent permitted by applicable Indian law and TagOn Store does not
        accept any liability for any errors or omissions.
      </p>
      <p>
        TagOn Store shall not be liable or responsible for any actions or
        inactions of sellers or any breach of conditions, representations or
        warranties by the sellers or manufacturers of the products and expressly
        disclaims any and all responsibility and liability in that regard. TagOn
        Store shall not mediate or resolve any dispute or disagreement between
        the User and the sellers or manufacturers of the products.
      </p>
      <p>
        TagOn Store through the App only provides a platform that users utilize
        to communicate and transact with one another and TagOn Store does not
        endorse any products displayed on the App or views expressed or
        information uploaded by any User. In no event TagOn Store shall be
        liable for any information, content, materials, products or services
        available through the App.
      </p>
      <p>
        The right, title, interest and ownership in the products that are
        transacted by the users through the App does not vest with TagOn Store
        and TagOn Store holds no right, title or ownership over such products.
        The offer and acceptance of products through the App is at the
        User&#39;s sole risk and TagOn Store accepts no liability for any errors
        or omissions or defaults or deficiency of any products either offered or
        acceptance by the users through the App.
      </p>
      <p>
        TagOn Store hereby disclaims any guarantees of exactness as to the
        finish and appearance of the product as ordered by and delivered to the
        User. The quality or quantity of any products, information or other
        material purchased or obtained by You through the App that may not meet
        Your expectations.
      </p>
      <p>
        The App could also be linked to third party websites and TagOn Store
        assumes no responsibility for results obtained from the use of these
        websites.
      </p>
      <p>
        TagOn Store makes no assurances that the App is bug free and User
        friendly or the safety of the use of the App. TagOn Store including
        affiliates, associates and their respective owners, directors, officers,
        agents, shareholders and partners shall not be liable in any manner with
        respect to the loss or damage incurred or suffered by You on account of
        virus attacks or bugs or other software or programmes that may be
        present or embedded while using or accessing the App.  
      </p>
      <p>
        TagOn Store does not guarantee that the services provided through the
        App will function without interruption or errors. The operation of the
        services may be interrupted due to maintenance, updates, or system
        failures, virus attacks, bugs or due to any other technical reasons or
        otherwise. App disclaims all liability for losses or damages caused by
        any such interruption or errors in functioning. TagOn Store also
        disclaims all liability for any malfunctioning impossibility of access
        or poor use conditions of the App due to inappropriate equipment,
        disturbances related to internet services, down time or otherwise.
      </p>
      <p>
        To the maximum extent permitted by applicable law, TagOn Store will have
        no liability related to User content arising under intellectual property
        rights, libel, privacy, publicity, obscenity or other laws. TagOn Store
        also disclaims all liability with respect to the misuse, loss,
        modification or unavailability of any User content.
      </p>
      <p>
        When the User deletes any kind of information the User must understand
        that the removed content may continue in backup copies.
      </p>
      <p>
        TagOn Store will not be liable for any loss that You may incur as a
        consequence of unauthorized use of the Account or any service or
        materials, either with or without Your knowledge.  TagOn Store shall not
        be liable for any third-party product, the advertisement available on
        e-mail or App with respect to the third-party website or the products
        and services are for information purpose only.
      </p>
      <h2 id="limitation-of-liability">LIMITATION OF LIABILITY</h2>
      <p>
        In no event TagOn Store including affiliates, associates and their
        respective owners, directors, officers, agents, shareholders and
        partners shall be liable for any loss or damage whatsoever including
        without limitation direct, indirect, punitive, incidental and
        consequential damages, lost profits, or damages resulting from the use
        or inability to use the App whether based on warranty, contract, tort
        (including negligence) or in any other means or action.
      </p>
      <p>
        You acknowledge that TagOn Store is only an Intermediary as per
        definition under the Information Technology Act, 2000 and rules framed
        thereunder, between You and the seller and TagOn Store will not be held
        liable for any act of seller or other third party service provider.
      </p>
      <p>
        Without prejudice, it is agreed by You that TagOn Store including
        affiliates, associates and their respective owners, directors, officers,
        agents, shareholders and partners shall not be held cumulatively liable
        for any damages or claims more than INR 1,000/- (Rupees One Thousand)
        subject to applicable law in respect of all matters and You acknowledge
        that this is reasonable assessment of risk and liabilities.
      </p>
      <h2 id="indemnity-">INDEMNITY  </h2>
      <p>
        You  agree to indemnify and hold harmless TagOn Store including
        affiliates, associates and their respective owners, directors, officers,
        agents, shareholders and partners from and against all actions,
        proceedings, claims, losses, damages and costs (including attorney fees)
        and other expenses suffered or incurred due to or attributable to or
        arising out of (i) violation of any applicable law (ii) breach of any
        representations, warranties or covenants of the User (iii) breach of
        the policies, guidelines and conditions of TagOn Store&#39;s service or
        Additional Terms or theseTerms or Privacy Policy (including any
        modifications or additional notifications or instructions); (iv)
        infringement of any third party rights. This Clause survives the
        termination of these Terms.
      </p>
      <h2 id="termination">TERMINATION</h2>
      <p>
        TagOn Store reserves the right to terminate the services provided to any
        User without prior notice in the event of any default committed by User
        including but not limited to the breach of any applicable law, policies,
        guidelines and conditions of TagOn Store&#39;s service or Additional
        Terms or these Terms or the Privacy Policy (including any modifications
        or additional notifications or instructions).  On such termination, the
        User&#39;s information and other details shall be black listed by the
        App and shall be taken into consideration whenever necessary.
      </p>
      <p>
        TagOn Store will terminate the services provided to any User if it is
        required under any applicable law.  Without prejudice, TagOn Store
        reserves its right to terminate the services if such provision of
        services to the users is not economically viable for TagOn Store. Users
        may choose to discontinue availing services and facilities provided
        through the App by closing the User account with the App.
      </p>
      <p>
        On termination of the User account, the User is not eligible to purchase
        or sell products through the App. Once the User&#39;s account is
        terminated for any reason whatsoever, the contents, reviews, comments or
        other material posted on the App by the User shall be removed by TagOn
        Store. 
      </p>
      <p>
        Post termination of the services provided to the User through the App,
        the User&#39;s personal information would reside in the App&#39;s
        database for a certain period of time. It is also clarified that certain
        terms and conditions of the policies, guidelines and conditions of TagOn
        Store&#39;s service or Additional Terms or these Terms and the Privacy
        Policy continue to be binding on the User and if any User by default or
        otherwise is found using or dealing with the App post termination of the
        service provided through the App, the User shall be prosecuted by TagOn
        Store. The User shall be also liable to compensate TagOn Store for any
        unauthorized use of the App.
      </p>
      <h2 id="miscellaneous">MISCELLANEOUS</h2>
      <p>
         Location Based Services : With your location permission, our
        App/website use information from Global Positioning System (GPS)
        networks, cellular, Wi-Fi or Bluetooth to determine your approximate
        location to provide you location based Services. We won&#39;t follow you
        all the time, but can help you find the nearest TagOn Store
        partner/Brand  store with Brand News, offers and/or other information
        based on your profile/liking when you need one.
      </p>
      <p>
        Severability: Each of the provisions in these Terms are severable and
        distinct from the others and if at any time one or more such provisions
        is or becomes invalid, illegal or unenforceable, the validity, legality
        and enforceability of the remaining provisions of these Terms shall not
        in any way be affected or impaired thereby.
      </p>
      <p>
        Captions: Various captions used in these Terms are for convenience
        purpose only and may not be used to interpret the provisions thereof. In
        case of captions and the related text conflicts, the text will prevail.
      </p>
      <p>
        Partial Invalidity: If any provision of these Terms is held to be
        illegal, invalid, or unenforceable under any present or future
        applicable Indian law, (i) such provision will be fully severable; (ii)
        these Terms will be construed and enforced as if such illegal, invalid,
        or unenforceable provision had never comprised a part hereof; (iii) the
        remaining provisions of these Terms will remain in full force and effect
        and will not be affected by the illegal, invalid, or unenforceable
        provision or by its severance here from; and (iv) in lieu of such
        illegal, invalid, or unenforceable provision, there will be added by
        TagOn Store a legal, valid, and enforceable provision to the extent
        possible.
      </p>
      <p>
        Notices: For purposes of service messages and notices about the services
        to User, the App may place a banner notice across its pages to alert the
        User to certain changes such as modifications to these Terms.
        Alternatively, notice may consist of an email from the App to the User.
        The User agrees that the App may communicate with User through the
        User&#39;s account or through other means including email or mobile.
        Users are required to update their settings regularly to ensure that the
        App&#39;s service messages and notices are received by the users timely
        and accurately.
      </p>
      <p>
        Assignment: The User shall not assign its rights or obligations under
        these Terms and the Privacy Policy to a third party.
      </p>
      <p>
        TagOn Store including affiliates, associates and their respective
        owners, directors, officers, agents, shareholders and partners shall
        have no liability for the non-receipt of the App&#39;s service messages
        and notices.
      </p>
      <h2 id="grievance-officer-">GRIEVANCE OFFICER  </h2>
      <p>
        In case of any complaints or grievances, the user may contact the
        Grievance Officer as under User will be contacted within 48 working
        hours for redressal.  
      </p>
      <h2 id="anuj-j-manager-">Anuj J (Manager)</h2>
      <p>
        Email ID: <a href="mailto:admin@tagostore.com">admin@tagostore.com</a>
      </p>
      <p>Phone number: +91-9987706817</p>
      <p>
        We are operational between 10:00 a.m. and 06:00 p.m., five days a week.
      </p>
      <p>
        Address: 6th Floor, K Raheja Prime, Marol Indl. Socy., Sag Baug Rd.,
        Marol Naka, Andheri (E), Mumbai - 400059
      </p>
      <p> </p>
      <h2 id="governing-law-and-jurisdiction-">
        GOVERNING LAW AND JURISDICTION  
      </h2>
      <p>
        The terms and conditions of this agreement shall be governed by Indian
        law and the courts in Mumbai shall only have exclusive jurisdiction to
        resolve any disputes.
      </p>
    </article>
  );
};
export default TermsConditionComponent;
