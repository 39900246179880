import { useEffect, useRef, useState } from "react";
import "./carousel.css";
import { ReactComponent as LeftIcon } from "../../assets/left.svg";
import { ReactComponent as RightIcon } from "../../assets/right.svg";

const moveTime = 500;
const waitTime = 1000;

export function Carousel({ images }) {
  const [current, setCurrent] = useState(0);
  const [moving, setMoving] = useState(false);
  const [indCur, setIndCur] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollRight(moveTime);
    }, moveTime + waitTime);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const getNextIndex = () => {
    return current === images.length - 1 ? 0 : current + 1;
  };
  const getPrevIndex = () => {
    return current === 0 ? images.length - 1 : current - 1;
  };

  const scrollRight = () => {
    if (ref.current) {
      setMoving(true);
      setIndCur(getNextIndex());
      const images = document.querySelectorAll(".advertisement");
      images.forEach((image) => {
        image.style.transition = `transform ${moveTime}ms`;
        image.style.transform = "translateX(-100%)";
      });
      setTimeout(() => {
        setCurrent(getNextIndex());
        images.forEach((image) => {
          image.style.transition = "transform 0s";
          image.style.transform = "translateX(0%)";
        });
        setMoving(false);
      }, moveTime);
    }
  };
  const scrollLeft = (duration) => {
    if (ref.current) {
      setMoving(true);
      setIndCur(getPrevIndex());
      const images = document.querySelectorAll(".advertisement");
      images.forEach((image) => {
        image.style.transition = `transform ${moveTime}ms`;
        image.style.transform = "translateX(100%)";
      });
      setTimeout(() => {
        setCurrent(getPrevIndex());
        images.forEach((image) => {
          image.style.transition = "transform 0s";
          image.style.transform = "translateX(0%)";
        });
        setMoving(false);
      }, moveTime);
    }
  };

  return (
    <div className="carousel-wrapper">
      <div ref={ref} className="carousel">
        <img
          className="advertisement"
          src={images[getPrevIndex()]}
          alt="advertisement"
          style={{
            position: "absolute",
            top: 0,
            left: "-100%",
            zIndex: 0,
          }}
        />
        <img
          className="advertisement"
          src={images[current]}
          alt="advertisement"
          style={{
            position: "absolute",
            top: 0,
            left: "0",
            zIndex: 0,
          }}
        />
        <img
          className="advertisement-fake"
          src={images[current]}
          alt="advertisement"
          style={{ zIndex: -2 }}
        />
        <img
          className="advertisement"
          src={images[getNextIndex()]}
          alt="advertisement"
          style={{
            position: "absolute",
            top: 0,
            left: "100%",
            zIndex: 0,
          }}
        />
      </div>
      <button className="c-prev" onClick={() => scrollLeft()} disabled={moving}>
        <LeftIcon />
      </button>
      <button
        className="c-next"
        onClick={() => scrollRight()}
        disabled={moving}
      >
        <RightIcon />
      </button>
      <div className="indicator">
        {images.map((image, idx) => (
          <div
            key={idx}
            className={"dot" + (idx === indCur ? " active" : "")}
          />
        ))}
      </div>
    </div>
  );
}
