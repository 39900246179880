import "./index.css";
import { ReactComponent as Logo } from "./assets/logo.svg";
// import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as FooterLogo } from "./assets/footer_logo.svg";
import { ReactComponent as Facebook } from "./assets/facebook.svg";
import { ReactComponent as Linkedin } from "./assets/linkedin.svg";
import { ReactComponent as Insta } from "./assets/insta.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

export function Layout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="shop-now">
      <div className="nav-wrapper">
        <div className="nav container">
          <Link to="/">
            <Logo className="nav-tagon-icon" />
          </Link>
          {/* <User className="nav-user-icon" /> */}
        </div>
      </div>
      <div className="container" style={{ flex: 1 }}>
        {children}
      </div>
      <div className="footer">
        <div className="container footer-container">
          <div>
            <div className="ql-title">QUICK LINKS</div>
            <div className="p-10" />
            <div className="ql-links">
              <HashLink
                to="/#about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                About Us
              </HashLink>
              <HashLink
                to="/#contactus"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Contact Us
              </HashLink>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-condition">Terms & Conditions</Link>
            </div>
          </div>
          <FooterLogo className="footer-logo" />
          <div>
            <div className="ql-title">Find Us On</div>
            <div className="p-10" />
            <div
              className="ql-links"
              style={{ flexDirection: "row", gap: "15px", flexWrap: "wrap" }}
            >
              <a
                href="https://www.facebook.com/pentagonbow/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook width={40} />
              </a>
              <a
                href="https://www.instagram.com/pentagontape/"
                target="_blank"
                rel="noreferrer"
              >
                <Insta width={40} />
              </a>
              <a
                href="https://in.linkedin.com/company/pentagon-tapes"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin width={40} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
