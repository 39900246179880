import React from "react";
import companyLogo from "./images/TagOn.png";
import mobileImages from "./images/main_mobileImgaes.png";
import qrCode from "./images/qr-code.png";
import { NavHashLink as Link } from "react-router-hash-link";

const LandingHomeComponent = () => {
  return (
    <div className="home-Wrapper">
      <nav>
        <div className="logo">
          <img src={companyLogo} alt="tagon_logoImage" />
        </div>
        <ul className="menu-list">
          {/* <li>
            <Link
              to="/shop_now"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Shop now
            </Link>
          </li> */}
          <li>
            <Link
              to="#about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/#contactus"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Contact us
            </Link>
          </li>
        </ul>
      </nav>
      <main id="hero">
        <div className="hero-section">
          <div className="home-textSection">
            <h1>
              Discover the Ultimate <b>Shopping</b> Experience
            </h1>
            <p>
              Shop with Ease, Our intuitive shopping app brings you a seamless
              browsing and purchasing experience, making it effortless to find
              and buy your favorite products.
            </p>
            <div style={{ padding: "10px" }} />
            <div className="downloads">
              <a
                href="https://play.google.com/store/apps/details?id=com.tagonstore.shop"
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img
                  src="https://luckywheel-staging-bucket.s3.ap-south-1.amazonaws.com/static/email_img/Social+Media+Icons+-+13.6.23/play_download.png"
                  alt="play_store"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "bold",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src={qrCode}
                    alt="play_store"
                    style={{ height: "100px", width: "100px" }}
                  />
                  <div>
                    <div>Scan the QR code to download</div>
                    <div>the TagOn app</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="home-imageSection">
            <img src={mobileImages} alt="Home-Mobile_Images" />
          </div>
        </div>
      </main>
    </div>
  );
};
export default LandingHomeComponent;
