import { useEffect, useState } from "react";
import { data } from "./data";
import Card from "./components/card/card";
import { useParams } from "react-router-dom";

export function ProductDetails() {
  // const d = data[0];

  const params = useParams();
  const [activeImage, setActiveImage] = useState(0);
  const d = data.find((x) => params.id.toString() === x.id.toString());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="p-5" />
      <div className="section-wrapper">
        <div className="section product-details">
          <div className="images-section">
            <div className="images">
              {d.product_images.map((image, idx) => (
                <img
                  className={
                    "product-small-image" +
                    (idx === activeImage ? " active" : "")
                  }
                  key={idx}
                  src={image.image}
                  alt="product"
                  onClick={() => setActiveImage(idx)}
                />
              ))}
            </div>
            <img
              className="product-image"
              src={d.product_images[activeImage].image}
              alt="product"
            />
          </div>
          <div className="pd">
            <div className="pd-title">{d.title}</div>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className="rating-box">{d.stars.toFixed(1)}</div>
              <Stars count={d.stars} />
              <div style={{ fontSize: "13px" }}>{d.star_count}</div>
            </div> */}
            <div className="pd-description">{d.description}</div>
            <div className="pd-price">
              Inclusive of all taxes
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  gap: "8px",
                  fontSize: "20px",
                  color: "black",
                }}
              >
                <div style={{ fontWeight: 500 }}>
                  {d?.country_details?.currency_symbol}
                  {d?.country_details.discount_price}
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    textDecoration: "line-through",
                    color: "#949292",
                  }}
                >
                  {d.country_details.currency_symbol}
                  {d.country_details.product_price}
                </div>
                <div style={{ fontSize: "15px", color: "#B00" }}>
                  {d.country_details.discout_percent + "%"}
                </div>
              </div>
            </div>
            {d.has_color && (
              <div className="pd-color-section">
                Colors
                <div className="pd-colors">
                  {d.color.map((color, idx) => (
                    <div
                      key={idx}
                      className="pd-color"
                      style={{ backgroundColor: color.hex_code }}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="pd-sold-by">Sold by {d.seller_name}</div>
            <div className="p-5" />
            {d.information && d.information.length > 0 && (
              <div className="pd-parameter-section">
                {d.information.map((parameter, idx) => (
                  <div key={idx} className="pd-parameter">
                    {parameter.product_info}
                    <div className="pd-params-grid">
                      {parameter.all_parameters.map((param) => (
                        <>
                          <div
                            className="pd-param-label"
                            key={param.product_property_type}
                          >
                            {param.product_property_type}
                          </div>
                          <div
                            className="pd-param-value"
                            key={param.values?.[0]?.id}
                          >
                            {param.values?.[0]?.name}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-5" />
      {d.you_may_like && d.you_may_like.length > 0 && (
        <>
          <div className="section-wrapper">
            <div className="section">
              <div className="section-title">Related Products</div>
              <div className="p-5" />
              <div className="cards">
                {d.you_may_like.map((yml, idx) => (
                  <Card key={idx} data={data.find((x) => x.id === yml)} />
                ))}
              </div>
            </div>
          </div>
          <div className="p-5" />
        </>
      )}
    </>
  );
}
