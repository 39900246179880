import React from "react";
import aboutUs_section_Img from "./images/about_mobileImages.png";

const LandingAboutComponent = () => {
  return (
    <div className="aboutUs-wrapper" id="about">
      <h2>About Us</h2>
      <div className="about-section">
        <div className="about-imageSection">
          <img src={aboutUs_section_Img} alt="AboutUs_Main_Image" />
        </div>
        <div className="about-paradivision">
          <p>
            TagOn Store offers exclusive range of products which are
            internationally acclaimed & authentic.
          </p>
          <p>
            Celebrate yourself with confidence and discover your own unique &
            personal style.
          </p>
          <p>
            We are proud of our ETHOS to be honest, simple, transparent &
            innovative.
          </p>
          <p>Let's work & grow together.</p>
        </div>
      </div>
    </div>
  );
};
export default LandingAboutComponent;
