import React, { useRef, useEffect } from "react";
import demoImage from "./images/demoMainImg.png";
import TagOnDemoVideo from "./images/TagOn_demo.mp4";

const LanndingDemoComponent = () => {
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);

  return (
    <main>
      <div className="demo-wrapper">
        <div className="demo-bodysection">
          <div className="demo-videoSection">
            <video src={TagOnDemoVideo} ref={vidRef} muted autoPlay loop />
          </div>
          <img src={demoImage} alt="demo_section_Main_Image" />
        </div>
      </div>
    </main>
  );
};
export default LanndingDemoComponent;
