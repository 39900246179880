import earth_global from "../images/earth_Globe.png";
import pay_image from "../images/pay.png";
import sell_image from "../images/sell.png";
import verify_account from "../images/verified_Account.png";
import last_hour from "../images/last_Hours.png";
import game_controller from "../images/game_Controller.png";

export const serviceSectionData = [
  {
    serviceQuoteImg: earth_global,
    serviceQuoteHead: "Global Product Available",
    serviceQuoteDescr: "Internationally Renowned Brands at your door step",
  },
  {
    serviceQuoteImg: pay_image,
    serviceQuoteHead: "Easy Payment Process",
    serviceQuoteDescr: "Convenience at your finger tips",
  },
  {
    serviceQuoteImg: sell_image,
    serviceQuoteHead: "Sell Your Own Products",
    serviceQuoteDescr: "List your products, sell & let’s grow together",
  },
  {
    serviceQuoteImg: verify_account,
    serviceQuoteHead: "Authenticate Product",
    serviceQuoteDescr: "Assurity of 100% genuine products",
  },
  {
    serviceQuoteImg: last_hour,
    serviceQuoteHead: "24*7 Customer Service",
    serviceQuoteDescr: "We are here to help you 24*7",
  },
  {
    serviceQuoteImg: game_controller,
    serviceQuoteHead: "Games and Many more",
    serviceQuoteDescr: "Play, enjoy & earn",
  },
];
